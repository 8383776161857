import React from 'react'
import flow from 'lodash.flow'
import { Button, Tooltip } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { Role } from '../../enums/Role'
import { withAllTags } from '../../screenWrappers/DataProviders'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  asBaseScreen,
  withAllTags,
])

/**
 * Displays a list of DataLab projects
 */
class ProjectsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
    }
  }

  componentDidUpdate(prevProps) {
    // rather than compare all the data, the data provider attaches a timestamp - if it changes then we know to update
    if (prevProps.timestamp !== this.props.timestamp) {
      this.setState({ projectsData: this.props.projects })
    }

    if (this.state.loading && this.props.tags) {
      this.setState({ loading: false })
    }
  }

  handleRowSelected(rowId) {
    this.props.navigate(`/projects/${rowId}`)
  }

  handleNewBtnClick(event) {
    this.props.navigate('/projects/newProject')
  }

  render() {
    return (
      this.state.loading
        ? (
          <>
            {[...Array(15)].map((n, index) => <Skeleton key={index} height={50} style={{ margin: '20px' }} />)}
          </>
        )

        : (
          <RequiredRoleBoundary
            allowedRoles={[Role.ADMIN]}
            functionProps={[
              'onClick',
            ]}
          >
            <div className="action-button-container">
              <Tooltip title="Create a new DataLab Project">
                <Button nightwatch="button-new-project" variant="contained" color="primary" onClick={this.handleNewBtnClick.bind(this)}>
                  New Project&nbsp;&nbsp;
                  <i className="fas fa-plus-circle" />
                </Button>
              </Tooltip>
            </div>

            <PaginatedDataTable
              nightwatch="datatable-projects"
              tableCaption="List of DataLab Projects"
              datalabFacade={this.props.datalabFacade}
              collectionName="projects"
              podId={this.props.user.pod}
              headers={[{ id: 'uuid', type: 'string', label: 'ID' },
                { id: 'projectName', type: 'string', label: 'Project Name' },
                { id: 'projectStorageSize', type: 'number', label: 'Project Storage Size(TB)' },
                { id: 'organisation', type: 'string', label: 'Organisation' },
                { id: 'description', type: 'string', label: 'Description' },
                {
                  id: 'status', type: 'status', label: 'Status', statusEnums: ['OPEN', 'BUILDING', 'CLOSED', 'ARCHIVED'],
                },
                { id: 'startDate', type: 'date-time', label: 'Start Date' },
                { id: 'endDate', type: 'date-time', label: 'End Date' },
                { id: 'closedDate', type: 'date-time', label: 'Closed Date' },
                {
                  id: 'users', type: 'array', label: 'Users', linkTo: 'users',
                },
                { id: 'databricksWorkspace', type: 'string', label: 'Databricks' },
                { id: 'tags', type: 'tag-array', label: 'Tags' },
              ]}
              linkKey="uuid"
              defaultSort={{ property: 'startDate', direction: 'desc' }}
              defaultFilter={{
                property: 'status', condition: '$eq', value: 'OPEN', displayValue: 'OPEN',
              }}
              onRowChangeCallback={this.handleRowSelected.bind(this)}
              rowKey="uuid"
              csvExportFileName="datalab_projects"
              tags={this.props.tags}
            />
          </RequiredRoleBoundary>
        )
    )
  }
}

export default wrap(ProjectsScreen)
