import CommonRoutes from './CommonRoutes';
import UsersScreen from '../screens/admin/UsersScreen';
import ProjectsScreen from '../screens/admin/ProjectsScreen';
import AssignUserScreen from '../screens/admin/AssignUserScreen';
import RemoveUserScreen from '../screens/admin/RemoveUserScreen';
import NewProjectScreen from '../screens/admin/NewProjectScreen';
import UpdateProjectScreen from '../screens/admin/UpdateProjectScreen';
import CloneProjectScreen from '../screens/admin/CloneProjectScreen';
import NewUserScreen from '../screens/admin/NewUserScreen';
import UpdateUserScreen from '../screens/admin/UpdateUserScreen';
import UserDetailsScreen from '../screens/admin/UserDetailsScreen';
import ProjectDetailsScreen from '../screens/admin/ProjectDetailsScreen';
import VmDetailsScreen from '../screens/admin/VmDetailsScreen';
import RManagementScreen from '../screens/admin/PackageManagementScreen';
import VmsScreen from '../screens/admin/VmsScreen';
import ChangeActiveVmScreen from '../screens/admin/ChangeActiveVmScreen';
import ProductsScreen from '../screens/admin/ProductsScreen';
import NewProductScreen from '../screens/admin/NewProductScreen';
import UpdateProductScreen from '../screens/admin/UpdateProductScreen';
import LinkProductsScreen from '../screens/admin/LinkProductsScreen';
import OrganisationsScreen from '../screens/admin/OrganisationsScreen';
import NewOrganisationScreen from '../screens/admin/NewOrganisationScreen';
import UpdateOrganisationScreen from '../screens/admin/UpdateOrganisationScreen';
import SessionsScreen from '../screens/admin/SessionsScreen';
import TagsScreen from '../screens/admin/TagsScreen';
import NewTagScreen from '../screens/admin/NewTagScreen';
import UpdateTagScreen from '../screens/admin/UpdateTagScreen';

/**
 * Paths applicable to Admins, beware that these need to be ordered correctly for breadcrumbs to work
 * ie users/ should be before users/:userName
 */
export default [
    {   
        //The default route
        path: "/",
        name: "Projects",
        Component: ProjectsScreen
    },
    {
        path: "/products",
        name: "Products",
        Component: ProductsScreen
    },
    {
        path: "/products/newProduct",
        name: "New Product",
        Component: NewProductScreen
    },
    {
        path: "/projects",
        name: "Projects",
        Component: ProjectsScreen
    },
    {
        path: "/projects/newProject",
        name: "New Project",
        Component: NewProjectScreen
    },
    {
        path: "/projects/:projectId",
        name: "projectId",
        Component: ProjectDetailsScreen
    },
    {
        path: "/projects/:projectId/assignUser",
        name: "Assign Users",
        Component: AssignUserScreen
    },
    {
        path: "/projects/:projectId/removeUser",
        name: "Remove Users",
        Component: RemoveUserScreen
    },

    {
        path: "/projects/:projectId/updateProject",
        name: "Update Project",
        Component: UpdateProjectScreen
    },
    {
        path: "/projects/:projectId/cloneProject",
        name: "New Project",
        Component: CloneProjectScreen
    },
    {
        path: "/projects/:projectId/linkProducts",
        name: "Link Products to Project",
        Component: LinkProductsScreen
    },
    {
        path: "/users",
        name: "Users",
        Component: UsersScreen
    },
    {
        path: "/users/newUser",
        name: "New User",
        Component: NewUserScreen
    },
    {
        path: "/users/:userName",
        name: "userName",
        Component: UserDetailsScreen
    },
    {
        path: "/users/:userName/updateUser",
        name: "Update User",
        Component: UpdateUserScreen
    },
    {
        path: "/users/:userName/changeActiveDsvm",
        name: "Change User's Active VM",
        Component: ChangeActiveVmScreen
    },
    {
        path: "/packageManagement",
        name: "Package Management",
        Component: RManagementScreen
    },
    {
        path: "/vms",
        name: "Virtual Machines",
        Component: VmsScreen
    },
    {
        path: "/vms/:VMname",
        name: "VMname",
        Component: VmDetailsScreen
    },    
    {
        path: "/organisations",
        name: "Organisations",
        Component: OrganisationsScreen
    },
    {
        path: "/organisations/newOrganisation",
        name: "New Organisation",
        Component: NewOrganisationScreen
    },
    {                              
        path: "/organisations/:organisationId/updateOrganisation",
        name: "Update Organisation",
        Component: UpdateOrganisationScreen
    },
    {
        path: "/sessions",
        name: "Desktop Sessions",
        Component: SessionsScreen
    },
    {
        path: "/tags",
        name: "Tags",
        Component: TagsScreen
    },
    {
        path: "/tags/newTag",
        name: "New Tag",
        Component: NewTagScreen
    },
    {
        path: "/tags/:tagKey/updateTag",
        name: "Update Tag",
        Component: UpdateTagScreen
    },
    {                              
        path: "/products/:name/updateProduct",
        name: "Update Product",
        Component: UpdateProductScreen
    },
].concat(CommonRoutes);
