import React from 'react'
import {
  TableCell, TableRow, Button, Checkbox,
  Tooltip,
  Chip, List,
  ListItem,
  ListItemText,
  Link,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import UpdateOutlineIcon from '@mui/icons-material/Edit' // '@mui/icons-material/EditAttributes';
import { styled } from '@mui/material/styles'
import GetAppIcon from '@mui/icons-material/GetApp'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import lodash from 'lodash'
import { format } from 'date-fns-tz'
import PropTypes from 'prop-types'
import * as StringUtils from '../../util/StringUtils'
import { tableHeadersProps } from './propTypes'

const propTypes = {
  // row is a dynamic type based on the tableHeaders, if tableHeaders does not include a matching
  // entry for an object key in row it will not be displayed in the table
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  isItemSelected: PropTypes.bool,
  isItemChecked: PropTypes.bool,
  handleSelectRow: PropTypes.func,
  handleCheckboxSelect: PropTypes.func,
  checkboxSelectable: PropTypes.bool,
  rowKey: PropTypes.string.isRequired,
  statusKey: PropTypes.string,
  index: PropTypes.number.isRequired,
  tableHeaders: tableHeadersProps.isRequired,
  handleFilterIdLink: PropTypes.func,
  handleFilterParentIdLink: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleClickThrough: PropTypes.func,
  linkKey: PropTypes.string,
  onRowChangeCallback: PropTypes.func,
  deletable: PropTypes.func,
  unlockable: PropTypes.func,
  updatable: PropTypes.func,
  downloadable: PropTypes.func,
  disableArrayLinks: PropTypes.bool,
  expandedCells: PropTypes.bool,
}

const CELL_STATUSES = [
  {
    values: ['open', 'active', 'ready', 'succeeded', 'running'],
    status: 'green',
  },
  {
    values: ['building', 'in progress', 'deallocating', 'stopping', 'starting'],
    status: 'building',
  },
  {
    values: ['dormant'],
    status: 'dormant',
  },
  {
    values: ['error', 'failed'],
    status: 'red',
  },
]

const defaultProps = {
  statusKey: null,
  disableArrayLinks: null,
  expandedCells: false,
  deletable: null,
  unlockable: null,
  updatable: null,
  downloadable: null,
  linkKey: null,
  onRowChangeCallback: null,
  checkboxSelectable: false,
  handleClickThrough: () => {},
  isItemSelected: false,
  isItemChecked: false,
  handleFilterIdLink: () => {},
  handleCheckboxSelect: () => {},
  handleSelectRow: () => {},
  handleKeyPress: () => {},
  handleFilterParentIdLink: () => {},
}

const RowText = styled('p')((
  {
    theme,
  }
) => ({
  fontSize: '.85em',
  '& .fas.fa-eye, .fas.fa-angle-double-right': {
    color: theme.palette.primary.main,
  },
}))

const RowLink = styled(Link)((
  {
    theme,
  }
) => ({
  fontSize: '.85em',
  '& .fas.fa-eye, .fas.fa-angle-double-right': {
    color: theme.palette.primary.main,
  },
  cursor: 'pointer',
}))

function Row({
  row,
  isItemSelected,
  isItemChecked,
  handleSelectRow,
  checkboxSelectable,
  handleCheckboxSelect,
  rowKey,
  statusKey,
  index,
  tableHeaders,
  handleFilterIdLink,
  handleFilterParentIdLink,
  handleKeyPress,
  handleClickThrough,
  linkKey,
  onRowChangeCallback,
  deletable,
  unlockable,
  updatable,
  downloadable,
  disableArrayLinks,
  expandedCells,

}) {
  const cellContent = (header, cellKey, cellData) => {
    switch (header.type) {
      case 'array': return (
        <Tooltip
          disableHoverListener={!cellData || !cellData.length}
          title={cellData && cellData.length
            ? (
              <List dense component="nav" aria-label={header.id}>
                {cellData.map((element) => (
                  <ListItem
                    component="a"
                    key={JSON.stringify(element)}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        color: 'secondary.main',
                      },
                    }}
                    href={`${header.linkTo}/${element}`}
                    disabled={disableArrayLinks}
                  >
                    <ListItemText primary={element} />
                  </ListItem>
                ))}
              </List>
            )
            : ''}
        >

          {cellData && cellData.length
            ? (
              <RowText>
                <i className="fas fa-eye" />
                &nbsp;&nbsp;
                {cellData.length}
              </RowText>
            )
            : <div />}

        </Tooltip>
      )
      case 'array-id-link': return (
        <Tooltip
          disableHoverListener={!cellData || !cellData.length}
          title={cellData && cellData.length
            ? `There are ${cellData.length} ${header.label}(s)`
            : ''}
        >
          <RowLink onClick={() => handleFilterParentIdLink(row[rowKey])}>
            {cellData && cellData.length
              ? (
                <>
                  <i className="fas fa-angle-double-right" />
                  &nbsp;&nbsp;
                  {cellData.length}
                </>
              )
              : ''}
          </RowLink>
        </Tooltip>
      )
      case 'id-link': return (
        <Tooltip
          title={cellData || ''}
          disableHoverListener={!cellData}
          interactive="true"
        >
          <RowLink onClick={() => handleFilterIdLink(cellData)}>{cellData}</RowLink>
        </Tooltip>
      )

      case 'tag-array': return (
        <Tooltip
          disableHoverListener={!cellData || !cellData.length}
          interactive="true"
          title={cellData && cellData.length
            ? (
              <List dense component="nav" aria-label={header.id}>
                {cellData.map((element) => (
                  <ListItem key={`${element.tagKey}-${element.tagValue}`}>
                    <Chip
                      tabIndex={-1}
                      label={(
                        <p>
                          <b>
                            {element.tagKey}
                            :
                          </b>
                          {' '}
                          {element.tagValue}
                        </p>
                      )}
                      size="small"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'secondary.main',
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )
            : ''}
        >
          {cellData && cellData.length
            ? (
              <RowText>
                <i className="fas fa-eye" />
                &nbsp;&nbsp;
                {cellData.length}
              </RowText>
            )
            : <div />}

        </Tooltip>
      )
      case 'tag-value-array': return (
        <Tooltip
          disableHoverListener={!cellData || !cellData.length}
          interactive="true"
          title={cellData && cellData.length
            ? (
              <List dense component="nav" aria-label={header.id}>
                {cellData.map((element) => (
                  <ListItem>
                    <Chip
                      key={element}
                      tabIndex={-1}
                      label={element}
                      size="small"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'secondary.main',
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )
            : ''}
        >
          {cellData && cellData.length
            ? (
              <RowText>
                <i className="fas fa-eye" />
                &nbsp;&nbsp;
                {cellData.length}
              </RowText>
            )
            : <div />}

        </Tooltip>
      )

      default: return (
        <Tooltip title={cellData || ''} disableHoverListener={!cellData} interactive="true">
          {cellKey === linkKey
            ? (
              <RowLink
                tabIndex={0}
                onKeyPress={(event) => handleKeyPress(event, row[rowKey], onRowChangeCallback)}
                onClick={(event) => handleClickThrough(event, row[rowKey], onRowChangeCallback)}
              >
                {cellData}
                {' '}
              </RowLink>
            )
            : <RowText>{cellData}</RowText>}
        </Tooltip>
      )
    }
  }

  /**
   * Get an appropriate cell status class name based on the cell data value
   * @param {*} data - The cell value data
   * @return {string} The class name to apply to the cell
   */
  const getStatusClassName = (data) => {
    const normalisedData = typeof data === 'string' ? data.toLowerCase() : data
    let status = 'grey'
    CELL_STATUSES.forEach((s) => {
      if (s.values.includes(normalisedData)) {
        status = s.status
      }
    })
    return `status-cell-${status}`
  }

  return (
    <TableRow
      hover
      tabIndex={-1}
      onClick={(event) => handleSelectRow(event, row[rowKey])}
      key={index}
      selected={isItemSelected}
    >
      {checkboxSelectable
      && (
      <TableCell key="options">
        <Checkbox
          onChange={(event) => handleCheckboxSelect(event, row[rowKey])}
          checked={isItemChecked} // Helps keep track of checked items when using quick
          disabled={(row[statusKey] !== 'READY') && (row[statusKey] !== 'DORMANT')}
        />
      </TableCell>
      )}

      {tableHeaders.map((header) => {
        const cellKey = header.id
        let cellData = row[cellKey]
        let applyStyle = 'table-cell'
        // Here we can modify how we display different datatypes in the cell
        // ie. if its an array, we can have a list of tag components
        switch (header.type) {
          case 'string':
            // limit string length
            if (cellData && !expandedCells && cellData.length > 50) {
              cellData = `${cellData.substring(0, 100)}...`
            }
            break
          case 'long-string':
            // dont limit string length
            break
          case 'string-title-case':
            cellData = lodash.startCase(cellData)
            break
          case 'status':
            if (!cellData) {
              cellData = header.defaultStatusEnum
            }
            applyStyle = getStatusClassName(cellData)
            break
          case 'date-time':
            if (cellData) {
              cellData = format(new Date(cellData), 'dd/MM/yyyy')
            }
            break
          case 'date-time-verbose':
            if (cellData) {
              cellData = format(new Date(cellData), 'dd/MM/yyyy hh:mm a')
            }
            break
          case 'date-time-verbose-or-live':
            if (cellData) {
              cellData = format(new Date(cellData), 'dd/MM/yyyy hh:mm a')
            } else {
              cellData = 'Live'
            }
            break
          case 'duration':
            cellData = StringUtils.millisToHoursMinutes(cellData)
            break
          case 'boolean':
            cellData = <i className={cellData ? 'table-row-yes fas fa-check' : 'table-row-no fas fa-times'} />
            break
          default:
            break
        }

        if (cellKey === linkKey) {
          applyStyle = 'id-table-cell'
        }

        // Populates the table with the data
        return (
          <TableCell className={applyStyle} key={cellKey} sx={{ width: 'fit-content' }}>
            {cellContent(header, cellKey, cellData)}
          </TableCell>
        )
      })}
      {/* If deletable prop then show delete icon */}
      {(deletable || updatable || downloadable || unlockable)
        && (
          <TableCell key="options">
            {deletable
              && (
                <Tooltip title="Delete">
                  <Button aria-label="delete row" variant="text" onClick={() => deletable(row[rowKey])}>
                    <DeleteOutlineIcon />
                  </Button>

                </Tooltip>
              )}
            {/* If updatable prop then show edit icon */}
            {updatable
              && (
                <Tooltip title="Edit">
                  <Button aria-label="update row" variant="text" onClick={() => updatable(row[rowKey])}>
                    <UpdateOutlineIcon />
                  </Button>

                </Tooltip>
              )}
            {/* If downloadable prop then show download icon */}
            {downloadable
              && (
                <Tooltip title="Download">
                  <Button aria-label="download row" variant="text" onClick={() => downloadable(row[rowKey])}>
                    <GetAppIcon />
                  </Button>

                </Tooltip>
              )}
            {/* If unlockable prop then show unlock icon */}
            {unlockable
              && (
                <Tooltip title="Unlock">
                  <Button aria-label="unlock row" variant="text" onClick={() => unlockable(row[rowKey])}>
                    <LockOpenIcon />
                  </Button>

                </Tooltip>
              )}
          </TableCell>
        )}

    </TableRow>
  )
}
Row.propTypes = propTypes
Row.defaultProps = defaultProps
export default Row
