import React from 'react';
import flow from 'lodash.flow';
import ProjectDetailsForm from '../../components/ProjectDetailsForm';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SkeletonForm from '../../components/SkeletonForm';
import { UserFixableError } from '../../util/Exceptions';
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { withAllVmOptionsConfig, withAllOrganisations, withAllTags, withProject, withAllUsers } from '../../screenWrappers/DataProviders';
import { withConfirmFeature, withLoadingFeature, withSnackbarsFeature } from '../../screenWrappers/Features';
import {
    Button,
    Container,
    Box,
    Divider,
} from "@mui/material";

//Screen requires the following data providers and features injected
const wrap = flow([
    withLoadingFeature,
    withConfirmFeature,
    withSnackbarsFeature,
    withAllVmOptionsConfig,
    withAllOrganisations,
    withAllTags,
    withAllUsers,
    withProject,
    asBaseScreen
])

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 *
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 * Handles both new and updating scenarios, updating boolean prop passed to component
 */
export class NewProjectScreen extends React.Component {
    constructor(props) {
        super(props);
        this.datalabFacade = props.datalabFacade;
        this.state = {
            loading: true,
            showingVmSizeHelp: false,
            vmSizeOptions: undefined,
            selectableTagValues: undefined,
            selectedTagKey: null,
            selectedTagValue: null,
            organisationData: undefined,
            userData: undefined,
        };
        this.formRef = React.createRef();
    }

    async componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.vmOptionsConfig && this.props.organisations && this.props.tags && this.props.project && this.props.users) {
            const selectableOrganisations = this.props.organisations.map(o => o.organisationName);
            const vmSizeOptions = this.props.vmOptionsConfig.filter(x => x.configType === 'vmSize')
            const vmTypeOptions = this.props.vmOptionsConfig.filter(x => x.configType === 'vmType')

            //if no vmSizeOptions, throw an error (lets not register any projects if theres no vm options...)
            if (vmSizeOptions.length === 0) { throw new UserFixableError("Apologies. There are no VM size options available and so Projects cannot be created/updated at this time.") };
            if (vmTypeOptions.length === 0) { throw new UserFixableError('There a no Virtual Machine type options available at the moment') }
            let defaultVmType = vmTypeOptions.find(vmTypeOption => vmTypeOption.type === "standard");
            let defaultVmSize = vmSizeOptions.find(vmSizeOption => vmSizeOption.type === "small");

            let defaultEndDate = new Date();
            defaultEndDate.setMonth(defaultEndDate.getMonth() + 2);

            let selectableUsers = this.props.users.filter(user => user.status === 'ACTIVE');

            let initialBasicsValues = {
                uuid: "",
                projectName: "",
                projectStorageSize: 1,
                description: "",
                projectContact: "",
                contactEmail: "",
                contactPhone: "",
                endDate: defaultEndDate,
                defaultVMsize: vmSizeOptions[0].type,
                organisation: "",
                tags: []
            }

            //set the initial values to the existing project
            Object.keys(initialBasicsValues).forEach(k => initialBasicsValues[k] = this.props.project[k])
            defaultVmSize = vmSizeOptions.find(vmSizeOption => vmSizeOption.type === this.props.project.defaultVMsize);
            if (!defaultVmSize) { throw new UserFixableError('The default Virtual Machine size for this Project is no longer available, please update in Project details') }

            this.setState({
                vmSizeOptions: vmSizeOptions,
                vmTypeOptions: vmTypeOptions,
                selectableOrganisations: selectableOrganisations,
                loading: false,
                defaultVmType: defaultVmType,
                defaultVmSize: defaultVmSize,
                basicsValues: initialBasicsValues,
                selectableUsers: selectableUsers
            })
        }
    }

    handleSubmit = (values) => {
        this.props.askForConfirmationListener('Are you sure you want to update this Project?',
            async () => {
                await this.props.datalabFacade.updateProject(values);
                this.props.showSnackbarSuccess(`Update Project (${this.props.project.uuid}) succeeded`);
            },
            { redirect: `/projects/${this.props.project.uuid}` }
        )
    }

    handleSubmitBasicsForm = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit()
        }
    }

    render() {

        return (
            this.state.loading ?
                <SkeletonForm />
                :
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className='details-grid'>
                        <ProjectDetailsForm
                            updating
                            vmSizeOptions={this.state.vmSizeOptions}
                            initialValues={this.state.basicsValues}
                            selectableUsers={this.state.selectableUsers}
                            selectableOrganisations={this.state.selectableOrganisations}
                            selectableTags={this.props.tags}
                            formRef={this.formRef}
                            handleSubmit={this.handleSubmit.bind(this)}
                            datalabFacade={this.props.datalabFacade}
                            podId = {this.props.user.pod} />
                    </div>
                    <Divider variant="middle" sx={{ borderColor: "rgba(0, 0, 0, 1)" }} />
                    <Container maxWidth="md">
                        <Box sx={{ width: '100%' }}>
                            <React.Fragment>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button sx={{ margin: "20px" }} variant="outlined" onClick={() => {
                                        this.handleSubmitBasicsForm()
                                    }}>
                                       Save
                                    </Button>
                                </Box>
                            </React.Fragment>
                        </Box>
                    </Container>
                </LocalizationProvider>
        );
    }

}

export default wrap(NewProjectScreen)
