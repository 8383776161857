import React from 'react'
import {
  Button,
  Autocomplete, Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions, MenuItem,
  FormHelperText,
  Select,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { FilterConditionLabel } from '../../enums/FilterConditionLabel'
import {
  tagShape,
} from '../../propTypeShapes'
import { tableHeadersProps, filterProps } from './propTypes'

const propTypes = {
  filters: PropTypes.arrayOf(filterProps).isRequired,
  setFiltering: PropTypes.func.isRequired,
  handleAddFilter: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(tagShape).isRequired,
  tableCaption: PropTypes.string.isRequired,
  headers: tableHeadersProps.isRequired,
}

const FilterSelect = styled(Select)({
  minWidth: '80%',
})

const OneThirdSection = styled('div')({
  minWidth: '33%',
  display: 'inline-block',
})

function getPossibleFilterConditions(header) {
  switch (header.type) {
    case 'string':
    case 'long-string':
    case 'string-title-case':
      return ['$regex', '$eq', '$ne']
    case 'id-link':
      return ['$regex', '$eq', '$ne']
    case 'status':
      return ['$eq', '$ne']
    case 'array':
      return ['$regex']
    case 'array-id-link':
      return ['$regex']
    case 'date-time':
      return ['$lte', '$gte']
    case 'date-time-verbose':
      return ['$lte', '$gte']
    case 'boolean':
      return ['is']
    case 'number':
      return ['$lt', '$gt', '$eq']
    case 'tag-value-array':
      return ['$eq', '$ne']
    case 'tag-array':
      return ['$elemMatch']
    default:
      throw new Error('invalid type')
  }
}

function TableFilterDialog({
  headers, filters, setFiltering, handleAddFilter, tags, tableCaption,
}) {
  const [selectedHeader, setSelectedHeader] = React.useState(headers.filter((h) => h.type !== 'object')[0])
  const [conditions, setConditions] = React.useState(
    {
      possible: getPossibleFilterConditions(selectedHeader),
      selected: getPossibleFilterConditions(selectedHeader)[0],
    }
  )
  const [selectedValue, setSelectedValue] = React.useState('')
  const [selectedTag, setSelectedTag] = React.useState('')

  const handleChangeHeader = (headerId) => {
    const header = headers.find((h) => h.id === headerId)
    const possibleConditions = getPossibleFilterConditions(header)
    // dont allow conditions already used for that header
    const existingConditions = filters.filter((e) => e.property === headerId).map((e) => e.condition)
    const newConditions = possibleConditions.filter((e) => !existingConditions.includes(e))
    setSelectedHeader(header)
    setConditions({ possible: newConditions, selected: newConditions[0] })
    if (header.type === 'date-time' || header.type === 'date-time-verbose') {
      setSelectedValue(new Date())
    } else {
      setSelectedValue('')
    }
  }

  const handleClose = () => {
    setFiltering(false)
  }

  const handleApply = () => {
    let filterValue = selectedValue
    let displayValue = selectedValue

    switch (selectedHeader.type) {
      case 'tag-array':
        conditions.selected = '$elemMatch'

        if (selectedValue) {
          // filter by tagKey AND tagValue
          displayValue = `${selectedTag.tagKey} - ${selectedValue}`
          filterValue = {
            $eq: {
              tagKey: selectedTag.tagKey,
              tagValue: selectedValue,
            },
          }
        } else {
          // filter by the tagKey alone
          displayValue = selectedTag.tagKey
          filterValue = {
            tagKey: selectedTag.tagKey,
            tagValue: { $regex: '' },
          }
        }
        break

      case 'string-title-case':
        // these strings are displayed as title case but stored as camel case
        // so doing a small conversion of the filter value is the quickest and simplest fix that is intuitive
        filterValue = lodash.camelCase(filterValue)
        break

      default:
        if (filterValue === undefined || filterValue === null || filterValue === '') {
          displayValue = ''
          filterValue = null
        }
        break
    }

    // filterValue is the value to use for filtering
    // displayValue is what to show the user (used for stuff like string title-case where the value
    // has to be changed under the hood)
    const newFilter = {
      property: selectedHeader.id, condition: conditions.selected, value: filterValue, displayValue,
    }

    handleAddFilter(newFilter)
    setFiltering(false)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        open
        maxWidth="md"
        fullWidth
        onClose={handleClose}
        aria-labelledby="filterForm-dialog-title"
      >
        <DialogTitle id="filter-form-dialog-title">
          Filter
          {' '}
          {tableCaption}
        </DialogTitle>
        <DialogContent>
          <OneThirdSection>
            <FilterSelect
              id="filter-select-header"
              value={selectedHeader.id}
              onChange={(e) => handleChangeHeader(e.target.value)}
              aria-describedby="helper-filter-select-header"
            >
              {headers.map((header) => (
                <MenuItem key={header.id} value={header.id}>
                  {header.label}
                  {' '}
                </MenuItem>
              ))}
            </FilterSelect>
            <FormHelperText id="helper-filter-select-header">
              Table attribute
            </FormHelperText>
          </OneThirdSection>

          {/* tags are a pain to deal with, so they need their own little bit of UI for filtering */}
          {(selectedHeader.type === 'tag-array')
            ? (
              <>
                {/* tag name box, used for tag keys */}
                <OneThirdSection>
                  <FilterSelect
                    id="tag-select-key"
                    value={selectedTag || ''}
                    onChange={(e) => { setSelectedTag(e.target.value); setSelectedValue('') }}
                    aria-describedby="helper-tag-select-key"
                    disabled={!tags}
                  >
                    {tags && tags.map((tag) => (
                      <MenuItem key={tag.tagKey} value={tag}>
                        {' '}
                        {tag.tagKey}
                        {' '}
                      </MenuItem>
                    ))}
                  </FilterSelect>

                  <FormHelperText id="helper-tag-select-key">
                    Tag key
                  </FormHelperText>
                </OneThirdSection>
                {/* tag value box, used for tag values */}
                <OneThirdSection>
                  <FilterSelect
                    id="tag-select-value"
                    value={selectedTag ? selectedValue : ''}
                    onChange={(e) => { setSelectedValue(e.target.value) }}
                    aria-describedby="helper-tag-select-value"
                    disabled={!selectedTag || !selectedTag.tagValues}
                  >
                    {selectedTag && selectedTag.tagValues && selectedTag.tagValues.map((tagValue) => (
                      <MenuItem key={tagValue} value={tagValue}>
                        {' '}
                        {tagValue}
                        {' '}
                      </MenuItem>
                    ))}
                  </FilterSelect>

                  <FormHelperText id="helper-tag-select-value">
                    Tag value
                  </FormHelperText>
                </OneThirdSection>
              </>
            )
            : (
              <>

                {/* filter condition box, used for most value types */}
                <OneThirdSection>
                  <FilterSelect
                    id="filter-select-condition"
                    value={conditions.selected}
                    onChange={(e) => {
                      const newCondtions = { ...conditions }
                      newCondtions.selected = e.target.value
                      setConditions(newCondtions)
                    }}
                    aria-describedby="helper-filter-select-condition"
                  >
                    {conditions.possible.map((possibleCondition) => (
                      <MenuItem key={possibleCondition} value={possibleCondition}>
                        {FilterConditionLabel[possibleCondition]}
                        {' '}
                      </MenuItem>
                    ))}
                  </FilterSelect>
                  <FormHelperText id="helper-filter-select-condition">
                    Filter condition
                  </FormHelperText>
                </OneThirdSection>

                {/* filter value box, used for most value types */}
                <OneThirdSection>
                  {/* enums */}

                  {selectedHeader.type === 'status'
                    && (
                      <FilterSelect
                        id="filter-select-value"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        aria-describedby="helper-filter-select-value"
                      >
                        {selectedHeader.statusEnums.map((statusEnum) => (
                          <MenuItem key={statusEnum} value={statusEnum}>
                            {statusEnum}
                            {' '}
                          </MenuItem>
                        ))}
                      </FilterSelect>
                    )}

                  {/* text  */}
                  {[
                    'string',
                    'long-string',
                    'string-title-case',
                    'array',
                    'array-id-link',
                    'id-link'].includes(selectedHeader.type) && (
                      <TextField
                        margin="dense"
                        id="filter-select-value"
                        label="String"
                        value={selectedValue}
                        aria-describedby="helper-filter-select-value"
                        onChange={(e) => setSelectedValue(e.target.value)}
                      />
                  )}

                  {(selectedHeader.type === 'date-time' || selectedHeader.type === 'date-time-verbose')
                    && (
                      <DateTimePicker
                        variant="inline"
                        format="dd/MM/yyyy hh:mm a"
                        margin="normal"
                        value={selectedValue}
                        onChange={(e) => { setSelectedValue(e) }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}

                  {selectedHeader.type === 'boolean'
                    && (
                      <FilterSelect
                        id="filter-select-value"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        aria-describedby="helper-filter-select-value"
                      >
                        <MenuItem key="true" value>true</MenuItem>
                        <MenuItem key="false" value={false}>false</MenuItem>
                      </FilterSelect>
                    )}

                  {(selectedHeader.type === 'number')
                    && (
                      <TextField
                        margin="dense"
                        id="filter-select-value"
                        value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}
                        aria-describedby="helper-filter-select-value"
                      />
                    )}

                  {(selectedHeader.type === 'tag-value-array')
                    && (
                      <Autocomplete
                        id="filter-select-value"
                        options={tags ? [...new Set([].concat(...tags.map((tag) => tag.tagValues)))] : []}
                        onChange={(event, value) => { setSelectedValue(value) }}
                        aria-describedby="helper-filter-select-value"
                        renderInput={(params) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <TextField {...params} label="Tag Values" variant="outlined" fullWidth />
                        )}
                      />
                    )}

                  {/* tag-array isn't handled here, it should be handled by it's own code above */}

                  <FormHelperText id="helper-filter-select-value">
                    Filter value
                  </FormHelperText>
                </OneThirdSection>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleApply}
            color="primary"
            disabled={((conditions.selected !== '$eq' && conditions.selected !== '$ne') && !selectedValue)
              || (selectedHeader.type === 'tag-array' && (selectedTag === undefined || selectedTag === ''))}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  )
}
TableFilterDialog.propTypes = propTypes
export default TableFilterDialog
