import React, {
  useState, useEffect,
} from 'react'
import flow from 'lodash.flow'
import {
  FormControl,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  Typography,
  Button,
  Container,
  Checkbox,
  Box,
} from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CopyToClipboard from '../../components/CopyToClipboard'
import { generateInitialAzureAdPassword } from '../../util/PasswordUtils'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { getRuntimeConfig } from '../../util/Config'
import { withSnackbarsFeature, withConfirmFeature, withLoadingFeature } from '../../screenWrappers/Features'
import SkeletonForm from '../../components/SkeletonForm'
import { appUserShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'

const config = getRuntimeConfig()
const { AAD_SUFFIX } = config

// Screen requires the following data providers and features injected
const wrap = flow([
  withLoadingFeature,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  askForConfirmationListener: PropTypes.func.isRequired,
  showSnackbarSuccess: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    '@odata.context': PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    userPrincipalName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  updating: PropTypes.bool,
}

const defaultProps = {
  updating: false,
  userData: undefined,
}

/**
 * Using Formik to handle the form data movement
 * Using Yup to handle the form validation (works well with Formik)
 *
 * @see https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
 *
 */
function NewAdministratorScreen({
  user, askForConfirmationListener, datalabFacade, showSnackbarSuccess,
  updating, userData, startLoading, stopLoading,
}) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [initialPassword, setInitialPassword] = useState('')
  const [upn, setUpn] = useState('')
  const [showingInitialPassword, setShowingInitialPassword] = useState(false)
  const [showingFullPassword, setShowingFullPassword] = useState(false)

  if (error) {
    throw error
  }

  useEffect(() => {
    if (!updating || (updating && userData)) {
      setLoading(false)
    }
  }, [userData])

  const handleSubmitNew = ({
    userPrincipalName, displayName, email, readonly, dataadmin, useradmin,
  }) => {
    const pw = generateInitialAzureAdPassword()
    const u = userPrincipalName + AAD_SUFFIX
    askForConfirmationListener(
      'Are you sure you want to create this Administrator?',
      async () => {
        await datalabFacade.createAdministrator({
          userPrincipalName: u,
          displayName,
          email,
          initialPassword: pw,
          podId: user.pod,
          readonly,
          dataadmin,
          useradmin,
        })
        showSnackbarSuccess(`Create Administrator (${u}) succeeded`)
        setShowingInitialPassword(true)
        setInitialPassword(pw)
        setUpn(u)
      }
    )
  }

  const handleSubmitUpdate = ({ userPrincipalName, displayName, email }) => {
    askForConfirmationListener(
      'Are you sure you want to update this Administrator?',
      async () => {
        await datalabFacade.updateADUser({
          userPrincipalName, displayName, email, podId: user.pod,
        })
        showSnackbarSuccess(`Update Administrator (${userPrincipalName}) succeeded`)
      },
      { redirect: '/administrators' }
    )
  }

  const handleNewBtnClick = () => {
    setShowingFullPassword(false)
    setShowingInitialPassword(false)
    setInitialPassword('')
    setUpn('')
  }

  if (showingInitialPassword) {
    return (
      <>

        <Grid container spacing={3} alignItems="center" direction="column" sx={{ mt: 3 }}>

          <Grid
            container
            className="formRow"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            direction="row"
          >
            <Grid item xs={2}>
              <InputLabel sx={{ textAlign: 'right', mr: 2 }}>Username:</InputLabel>
            </Grid>
            <Grid item xs={4} sx={{ overflow: 'auto' }}>
              <Typography variant="h5" component="h3" sx={{ textAlign: 'center' }}>
                { upn }
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <CopyToClipboard>
                {({ copy }) => (

                  <Button
                    className="copy-to-clipboard-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => copy(upn)}
                  >
                    Copy
                  </Button>
                )}
              </CopyToClipboard>
            </Grid>

          </Grid>

          <Grid
            container
            className="formRow"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            direction="row"
          >
            <Grid item xs={2} sx={{ overflow: 'auto' }}>
              <InputLabel sx={{ textAlign: 'right', mr: 2 }}>Temporary password:</InputLabel>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5" component="h3" sx={{ textAlign: 'center' }}>
                {
                                showingFullPassword
                                  ? initialPassword
                                  : initialPassword.replace(/.*/g, '********')
                            }
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <CopyToClipboard>
                {({ copy }) => (

                  <Button
                    className="copy-to-clipboard-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => copy(initialPassword)}
                  >
                    Copy
                  </Button>
                )}
              </CopyToClipboard>
              <Button
                sx={{ ml: 1 }}
                variant="outlined"
                onClick={() => { setShowingFullPassword(!showingFullPassword) }}
              >
                {showingFullPassword ? 'Hide password' : 'Show password'}
              </Button>
            </Grid>

          </Grid>
        </Grid>

        <Box sx={{
          padding: '20px', mt: 3, ml: 'auto', mr: 'auto',
        }}
        >
          <Button variant="contained" color="primary" onClick={handleNewBtnClick}>
            Create another...&nbsp;&nbsp;
            <i className="fas fa-plus-circle" />
          </Button>
        </Box>
      </>
    )
  }

  return loading ? <SkeletonForm />
    : (
      <div className="details-grid">
        <Container maxWidth="md">

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              userPrincipalName: updating ? userData.userPrincipalName : '',
              displayName: updating ? userData.displayName : '',
              email: updating ? userData.email ?? '' : '',
              readonly: updating ? undefined : false,
              dataadmin: updating ? undefined : true,
              useradmin: updating ? undefined : true,
            }}
            onSubmit={(values) => {
              if (updating) {
                handleSubmitUpdate(values)
              } else {
                handleSubmitNew(values)
              }
            }}
            validationSchema={Yup.object().shape({
              userPrincipalName: updating ? Yup.string()
                : Yup.string()
                  .matches(/^\w+([.-]?\w+)*$/, 'May only contain characters, numbers, fullstops, and hyphens')
                  .test('isUnique', 'A User already exists with this userPrincipalName', (name) => {
                    if (!name) return true
                    return new Promise((resolve) => {
                      startLoading()
                      datalabFacade.isADUsernameUnique(name + AAD_SUFFIX).then((result) => {
                        stopLoading()
                        resolve(result.isUnique)
                      }).catch((err) => setError(err))
                    })
                  })
                  .required()
                  .label('userPrincipalName'),
              displayName: Yup.string()
                .matches(
                  /^\w+([!@#\\$%\\^\\&*\\)\\( +=._-]*\w+)*$/,
                  'May only contain characters, numbers and special characters, no tabs'
                )
                .min(2)
                .trim()
                .required()
                .label('Display name'),
              email: Yup.string().email()
                .required(),
              readonly: updating ? Yup.boolean() : Yup.boolean()
                .required(),
              dataadmin: updating ? Yup.boolean() : Yup.boolean()
                .required(),
              useradmin: updating ? Yup.boolean() : Yup.boolean()
                .required(),
            })}
          >

            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                updating={updating}
              />
            )}

          </Formik>
        </Container>
      </div>
    )
}

const form = {
  userPrincipalName: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  readonly: PropTypes.bool,
  dataadmin: PropTypes.bool,
  useradmin: PropTypes.bool,
}

const formPropTypes = {
  values: PropTypes.shape(form).isRequired,
  touched: PropTypes.shape(Object.fromEntries(Object.keys(form).map((k) => [k, PropTypes.bool]))).isRequired,
  errors: PropTypes.shape(Object.fromEntries(Object.keys(form).map((k) => [k, PropTypes.string]))).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
}

function Form({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  updating,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <p className="mandatory-info">All fields are required</p>

      <Grid container spacing={3} alignItems="center" direction="column">

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="userPrincipalName">Username</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="userPrincipalName"
                aria-describedby="helperuserPrincipalName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userPrincipalName}
                disabled={updating}
              />

              <FormHelperText
                id="helperuserPrincipalName"
                error={errors.userPrincipalName && touched.userPrincipalName}
              >
                {(errors.userPrincipalName && touched.userPrincipalName) ? errors.userPrincipalName
                  : 'The username of the Administrator'}
              </FormHelperText>
            </FormControl>
          </Grid>
          {!updating
                                    && (
                                    <Grid item xs={6}>
                                      <InputLabel>{AAD_SUFFIX}</InputLabel>
                                    </Grid>
                                    )}
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="displayName">Name</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                maxRows="4"
                id="displayName"
                aria-describedby="helperName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.displayName}
              />
              <FormHelperText
                id="helperName"
                error={errors.displayName && touched.displayName}
              >
                {(errors.displayName && touched.displayName) ? errors.displayName
                  : 'The display name for the Administrator'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          className="formRow"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <Grid item xs={2}>
            <InputLabel htmlFor="email">Email</InputLabel>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth>
              <TextField
                id="email"
                aria-describedby="helperEmail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                autoComplete="email"
              />
              <FormHelperText
                id="helperEmail"
                error={errors.email && touched.email}
              >
                {(errors.email && touched.email) ? errors.email
                  : 'Organisation contact email'}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {!updating

                                && (
                                  <>
                                    <Grid
                                      container
                                      className="formRow"
                                      spacing={1}
                                      alignItems="center"
                                      justifyContent="center"
                                      direction="row"
                                    >
                                      <Grid item>
                                        <Typography variant="h6" component="h2">
                                          Permissions
                                        </Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      container
                                      className="formRow"
                                      spacing={1}
                                      alignItems="center"
                                      justifyContent="center"
                                      direction="row"
                                    >
                                      <Grid item xs={2}>
                                        <InputLabel htmlFor="reader">Reader</InputLabel>
                                      </Grid>
                                      <Grid item xs>
                                        <FormControl>
                                          <Checkbox
                                            id="reader"
                                            style={{ width: '42px' }}
                                            checked
                                            disabled
                                            aria-describedby="helperreader"
                                          />
                                          <FormHelperText
                                            id="helperreadonly"
                                          >
                                            View metadata such as Users, Projects, and Products.
                                            Does not include read access to actual data in Azure storage.
                                            This role is default for all Administrators
                                          </FormHelperText>
                                        </FormControl>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      container
                                      className="formRow"
                                      spacing={1}
                                      alignItems="center"
                                      justifyContent="center"
                                      direction="row"
                                    >
                                      <Grid item xs={2}>
                                        <InputLabel htmlFor="projectadmin">Project Admin</InputLabel>
                                      </Grid>
                                      <Grid item xs>
                                        <FormControl>
                                          <Checkbox
                                            id="projectadmin"
                                            style={{ width: '42px' }}
                                            checked={!values.readonly}
                                            onChange={() => setFieldValue('readonly', !values.readonly)}
                                            aria-describedby="helperprojectadmin"
                                          />
                                          <FormHelperText
                                            id="helperprojectadmin"
                                            error={errors.readonly && touched.readonly}
                                          >
                                            {
                                              (errors.readonly && touched.readonly)
                                                ? errors.readonly
                                                : 'Create and manage Projects, assign Analysts to Projects, link Projects to Products (does not include permission to create Products or access data)'
                                            }
                                          </FormHelperText>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      className="formRow"
                                      spacing={1}
                                      alignItems="center"
                                      justifyContent="center"
                                      direction="row"
                                    >
                                      <Grid item xs={2}>
                                        <InputLabel htmlFor="dataadmin">Data Admin</InputLabel>
                                      </Grid>
                                      <Grid item xs>
                                        <FormControl>
                                          <Checkbox
                                            id="dataadmin"
                                            style={{ width: '42px' }}
                                            checked={values.dataadmin}
                                            onChange={() => setFieldValue('dataadmin', !values.dataadmin)}
                                            aria-describedby="helperdataadmin"
                                          />
                                          <FormHelperText
                                            id="helperdataadmin"
                                            error={errors.dataadmin && touched.dataadmin}
                                          >
                                            {
                                            (errors.dataadmin && touched.dataadmin)
                                              ? errors.dataadmin
                                              : 'Create and manage Products, including permission to read and write data to Azure storage locations'
                                              }
                                          </FormHelperText>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      className="formRow"
                                      spacing={1}
                                      alignItems="center"
                                      justifyContent="center"
                                      direction="row"
                                    >
                                      <Grid item xs={2}>
                                        <InputLabel htmlFor="useradmin">User Admin</InputLabel>
                                      </Grid>
                                      <Grid item xs>
                                        <FormControl>
                                          <Checkbox
                                            id="useradmin"
                                            style={{ width: '42px' }}
                                            checked={values.useradmin}
                                            onChange={() => setFieldValue('useradmin', !values.useradmin)}
                                            aria-describedby="helperuseradmin"
                                          />
                                          <FormHelperText
                                            id="helperuseradmin"
                                            error={errors.useradmin && touched.useradmin}
                                          >
                                            {(errors.useradmin && touched.useradmin)
                                              ? errors.useradmin
                                              : 'Create and manage Analyst user accounts'}
                                          </FormHelperText>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
      </Grid>

      <div className="action-button-container">

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </div>

    </form>
  )
}

Form.propTypes = formPropTypes

NewAdministratorScreen.propTypes = propTypes
NewAdministratorScreen.defaultProps = defaultProps
export default wrap(NewAdministratorScreen)

