/* eslint-disable react/jsx-props-no-spreading -- props spreading is used inside HOC */
import React from 'react'
import { useFeatures } from '../hooks/useFeatures'
import { useSnackbars } from '../hooks/useSnackbars'

// Deprecated. You should use the useFeatures hook instead
const withConfirmFeature = (WrappedComponent) => (function ConfirmWrapper(props) {
  const { setConfirm } = useFeatures()
  const askForConfirmationListener = (message, callback, additionalOptions) => {
    setConfirm({ message, callback, additionalOptions })
  }
  return <WrappedComponent {...props} askForConfirmationListener={askForConfirmationListener} />
})

// Deprecated. You should use the useFeatures hook instead
const withLoadingFeature = (WrappedComponent) => (function LoadingWrapper(props) {
  const { startLoading, stopLoading } = useFeatures()
  return (
    <WrappedComponent
      {...props}
      startLoading={startLoading}
      stopLoading={stopLoading}
    />
  )
})

// Deprecated. You should use the useSnackbars hook instead
const withSnackbarsFeature = (WrappedComponent) => (function SnackbarsWrapper(props) {
  const {
    showSnackbarInProgress,
    showSnackbarSuccess,
    showSnackbarWarning,
    showSnackbarFail,
    showSnackbarLoggedOut,
    showSnackbarTracking,
    showSnackbarCitrixStatus,
    closeSnackBar,
  } = useSnackbars()
  return (
    <WrappedComponent
      {...props}
      showSnackbarInProgress={showSnackbarInProgress}
      showSnackbarSuccess={showSnackbarSuccess}
      showSnackbarWarning={showSnackbarWarning}
      showSnackbarFail={showSnackbarFail}
      showSnackbarLoggedOut={showSnackbarLoggedOut}
      showSnackbarTracking={showSnackbarTracking}
      showSnackbarCitrixStatus={showSnackbarCitrixStatus}
      closeSnackBar={closeSnackBar}
    />
  )
})

export {
  withConfirmFeature,
  withLoadingFeature,
  withSnackbarsFeature,
}
