import React from 'react'
import { Typography } from '@mui/material'
import {
  Link,
} from 'react-router-dom'
import { styled, alpha } from '@mui/material/styles'

const StyledLink = styled(Link)((
  {
    theme,
  }
) => ({
  color: theme.palette.primary.main, fontSize: '14px', fontWeight: '450',
}
))

function Breadcrumbs({ crumbs, user }) {
  return (
    <div style={{
      margin: '5px 20px', alignItems: 'center', justifyContent: 'space-between', display: 'flex',
    }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Link back to any previous steps of the breadcrumb. */}
        {crumbs.map(({ name, path }, key) => (key + 1 === crumbs.length ? (
          <Typography sx={{ fontSize: '20px', fontWeight: '450' }} key={key}>
            {name}
          </Typography>
        ) : (
          path !== '/'
                        && (
                        <div key={key}>
                          <StyledLink to={path}>
                            {' '}
                            {name}
                          </StyledLink>
                          <i style={{ marginLeft: '5px', marginRight: '5px' }} className="spacer fas fa-caret-right" />
                        </div>
                        )

        )))}
      </div>
    </div>
  )
}
export default Breadcrumbs
