import React from 'react'
import flow from 'lodash.flow'
import { Button, Tooltip } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import { Role } from '../../enums/Role'
import { withAllTags } from '../../screenWrappers/DataProviders'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
  withAllTags,
])

/**
 * Displays a list of DataLab products
 */
class ProductsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading && this.props.tags) {
      this.setState({ loading: false })
    }
  }

  handleNewBtnClick(event) {
    this.props.navigate('/products/newProduct')
  }

  handleEditBtnClick(name) {
    this.props.navigate(`${name}/updateProduct`)
  }

  handleDeleteProduct(name) {
    this.props.askForConfirmationListener(`Are you sure you want delete this Product (${name})? All files in the Product folder will be deleted`, async () => {
      await this.datalabFacade.deleteProduct(name, this.props.user.pod)
      this.props.showSnackbarInProgress(`Delete Product (${name}) in progress...`)
    })
  }

  handleUnlockProduct(productName) {
    this.props.askForConfirmationListener(`WARNING! Any users with open files in this product [${productName}] will have their session closed. Proceed to unlock?`, async () => {
      await this.datalabFacade.removeFileHandlers(productName, this.props.user.pod)
      this.props.showSnackbarSuccess('Unlocked product succeeded')
    })
  }

  render() {
    return (
      this.state.loading
        ? (
          <>
            {[...Array(15)].map((n, index) => <Skeleton key={index} height={50} style={{ margin: '20px' }} />)}
          </>
        )

        : (
          <RequiredRoleBoundary
            allowedRoles={[Role.DATA_ADMIN]}
            functionProps={[
              'onClick',
              'deletable',
              'updatable',
              'unlockable',
            ]}
          >
            <div className="action-button-container">
              <Tooltip title="Create a new DataLab Product">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNewBtnClick.bind(this)}
                >
                  New Product&nbsp;&nbsp;
                  <i className="fas fa-plus-circle" />
                </Button>
              </Tooltip>
            </div>

            <PaginatedDataTable
              deletable={this.handleDeleteProduct.bind(this)}
              updatable={this.handleEditBtnClick.bind(this)}
              unlockable={this.handleUnlockProduct.bind(this)}
              tableCaption="List of DataLab Products"
              datalabFacade={this.props.datalabFacade}
              collectionName="products"
              podId={this.props.user.pod}
              headers={[
                { id: 'name', type: 'string', label: 'Short name' },
                { id: 'displayName', type: 'string', label: 'Long name' },
                { id: 'tags', type: 'tag-array', label: 'Tags' },
              ]}
              defaultSort={{ property: 'name', direction: 'asc' }}
              onRowChangeCallback={() => {}}
              rowKey="name"
              csvExportFileName="datalab_products"
              tags={this.props.tags}
            />
          </RequiredRoleBoundary>
        )
    )
  }
}

export default wrap(ProductsScreen)
