import React from 'react'
import flow from 'lodash.flow'
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import PaginatedDataTable from '../../components/PaginatedDataTable'
import { withRouter } from '../../screenWrappers/withRouter'
import { withAllVmOptionsConfig } from '../../screenWrappers/DataProviders'
import { appUserShape, vmOptionConfigShape } from '../../propTypeShapes'
import DatalabFacade from '../../dataService/DatalabFacade'
import { Role } from '../../enums/Role'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'
import DrawerDataTable from '../../components/DrawerDataTable'

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  asBaseScreen,
  withAllVmOptionsConfig,

])

const propTypes = {
  user: appUserShape.isRequired,
  datalabFacade: PropTypes.instanceOf(DatalabFacade).isRequired,
  vmOptionsConfig: PropTypes.arrayOf(vmOptionConfigShape),
}

const defaultProps = {
  vmOptionsConfig: null,
}

/**
 * Displays a list of DataLab VMs
 */
function VmsScreen({
  user, vmOptionsConfig, datalabFacade,
}) {
  const [loading, setLoading] = React.useState(true)
  const [checked, setChecked] = React.useState([])
  const [openDrawer, setDrawer] = React.useState(false)
  const [isUpdateVmSize, setIsUpdateVmSize] = React.useState(false)
  const navigate = useNavigate()

  const headers = [
    { id: 'VMname', type: 'string', label: 'Name' },
    { id: 'project', type: 'string', label: 'Project Name' },
    { id: 'buildDate', type: 'date-time', label: 'Registered Date' },
    { id: 'destroyDate', type: 'date-time', label: 'Date Destroyed / Rebuilt' },
    {
      id: 'status',
      type: 'status',
      label: 'Status',
      statusEnums: ['READY', 'BUILDING', 'DESTROYED', 'DORMANT', 'ERROR'],
    },
    {
      id: 'powerState',
      type: 'status',
      label: 'Power State',
      statusEnums: ['RUNNING', 'DEALLOCATED'],
      defaultStatusEnum: 'DEALLOCATED',
    },
    { id: 'assignedTo', type: 'string', label: 'Assigned To' },
    { id: 'type', type: 'string', label: 'Type' },
    { id: 'size', type: 'string', label: 'Size' },
    { id: 'version', type: 'string', label: 'Version' },
    { id: 'localDiskSize', type: 'number', label: 'Local Disk (GB)' }]

  React.useEffect(() => {
    if (vmOptionsConfig) {
      setLoading(false)
    }
  }, [vmOptionsConfig])

  const handleRowSelected = (rowId) => {
    navigate(`/vms/${rowId}`)
  }

  const handleChecked = (newChecked) => {
    setChecked(newChecked)
  }

  const handleUpdateVmType = () => {
    setDrawer(true)
  }

  const handleUpdateVmSize = () => {
    setDrawer(true)
    setIsUpdateVmSize(true)
  }

  const handleDrawerClose = () => {
    setDrawer(false)
    setIsUpdateVmSize(false)
  }

  if (loading) {
    return (
      <>
        {/* eslint-disable-next-line react/no-array-index-key */}
        {[...Array(15)].map((n, index) => <Skeleton key={index} height={50} style={{ margin: '20px' }} />)}
      </>
    )
  }

  const vmSizeOptions = vmOptionsConfig.filter((x) => x.configType === 'vmSize')
  const vmTypeOptions = vmOptionsConfig.filter((x) => x.configType === 'vmType')
  const vmVersionOptions = vmOptionsConfig.filter((x) => (
    x.configType === 'vmVersion' && x.availableForRebuild
  ))

  return (
    <RequiredRoleBoundary
      allowedRoles={[Role.ADMIN]}
      functionProps={[
        'onClick',
      ]}
    >
      {openDrawer
          && (
            <DrawerDataTable
              rowKey="VMname"
              checked={checked}
              openDrawer={openDrawer}
              isUpdateVmSize={isUpdateVmSize}
              rowsPerPage={25}
              tableHeaders={headers.filter((th) => th.type !== 'object')}
              tableData={checked}
              vmTypeOptions={vmTypeOptions}
              vmSizeOptions={vmSizeOptions}
              vmVersionOptions={vmVersionOptions}
              handleDrawerClose={handleDrawerClose}
              datalabFacade={datalabFacade}
              user={user}
            />
          )}
      <div className="action-button-container">
        <Button
          aria-label="update size"
          variant="text"
          color="primary"
          size="small"
          onClick={() => handleUpdateVmSize()}
          disabled={!(checked.length > 0)}
        >
          Update VM Size&nbsp;&nbsp;
          <i className="fa-sharp fa-solid fa-desktop" />
        </Button>
        <Button
          aria-label="update type"
          variant="text"
          color="primary"
          size="small"
          onClick={() => handleUpdateVmType()}
          disabled={!(checked.length > 0)}
        >
          Update VM Type/Version&nbsp;&nbsp;
          <i className="fa-sharp fa-solid fa-file-pen" />
        </Button>
      </div>
      <PaginatedDataTable
        tableCaption="List of DataLab Virtual Machines"
        datalabFacade={datalabFacade}
        collectionName="VMs"
        podId={user.pod}
        checkboxSelectable
        headers={headers}
        linkKey="VMname"
        defaultSort={{ property: 'buildDate', direction: 'desc' }}
        defaultFilter={{
          property: 'status', condition: '$ne', value: 'DESTROYED', displayValue: 'DESTROYED',
        }}
        vmSizeOptions={vmSizeOptions}
        vmTypeOptions={vmTypeOptions}
        vmVersionOptions={vmVersionOptions}
        onRowChangeCallback={handleRowSelected}
        onChecked={handleChecked}
        rowKey="VMname"
        statusKey="status"
        csvExportFileName="datalab_vms"
      />
    </RequiredRoleBoundary>
  )
}

VmsScreen.propTypes = propTypes
VmsScreen.defaultProps = defaultProps
export default wrap(VmsScreen)
