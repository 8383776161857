import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

//A dialog that display information in a list
export default function HelpDialog(props) {
  let [open] = React.useState(props.open);

  function handleYes() {
    props.callback(true);

  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Typography variant="caption" component="p">
            {props.message}
          </Typography>
        </DialogContent>
        {!props.hideButtons &&
          <DialogActions>
            <Button onClick={handleYes} color="primary" autoFocus>
              OK
          </Button>
          </DialogActions>
        }
      </Dialog>
    </div>
  );
}
