import PropTypes from 'prop-types'

export const tableHeadersProps = PropTypes.arrayOf(PropTypes.shape(
  {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(
      [
        'array',
        'array-id-link',
        'object',
        'id-link',
        'tag-array',
        'tag-value-array',
        'string',
        'long-string',
        'string-title-case',
        'status',
        'date-time',
        'date-time-verbose',
        'duration',
        'boolean',
        'number',
      ]
    ).isRequired,
    label: PropTypes.string.isRequired,
    statusEnums: PropTypes.arrayOf(PropTypes.string),
    linkTo: PropTypes.string,
  }
))

export const filterProps = PropTypes.shape({
  property: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string,
})

export const orderProps = PropTypes.shape({
  property: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
})
