import React from 'react';
import flow from 'lodash.flow';
import {
    Button,
    Typography,
} from "@mui/material";
import asBaseScreen from '../../screenWrappers/BaseScreen';
import { withAllProducts, withProjectsProducts } from '../../screenWrappers/DataProviders';
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features';
import TransferList from '../../components/TransferList';
import SkeletonForm from '../../components/SkeletonForm';
import ArrayAttributeRow from '../../components/ArrayAttributeRow.js';
import { withParams } from '../../screenWrappers/withParams';


//Screen requires the following data providers and features injected
const wrap = flow([
    withParams,
    withAllProducts,
    withProjectsProducts,
    withConfirmFeature,
    withSnackbarsFeature,
    asBaseScreen,
])

/**
 * Allows selecting products to be linked with a project
 */
class LinkProductsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.datalabFacade = props.datalabFacade;

        this.state = {
            loading: true,
            originalProductCount: undefined,
            projectId: undefined,
            products: undefined,
            linkedProducts: undefined,
            updatedLinkedProducts: undefined,
            confirming: false
        };
    }

    async componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.linkedProducts && this.props.products) {

            const selectableProducts = await this.datalabFacade.getProducts(this.props.user.pod);
            this.setState({
                loading: false,
                originalProductCount: this.props.linkedProducts.length,
                projectId: this.props.urlParams.projectId,
                products: selectableProducts,
                linkedProducts: this.props.linkedProducts,
                updatedLinkedProducts: this.props.linkedProducts
            })
        }
    }

    handleSubmit = () => {
        const addedProducts = this.state.updatedLinkedProducts.filter(e => !this.state.linkedProducts.includes(e))
        const removedProducts = this.state.linkedProducts.filter(e => !this.state.updatedLinkedProducts.includes(e))
        this.props.askForConfirmationListener(
            `Please confirm your changes`,
            async () => {
                await this.datalabFacade.linkProjectToProducts(this.state.projectId, this.state.updatedLinkedProducts, this.props.user.pod);
                this.props.showSnackbarInProgress(`Update Linked Products (${this.state.projectId}) in progress...`);
            },
            {
                redirect: `/projects/${this.state.projectId}`,
                additionalComponent: <React.Fragment>
                    <Typography variant="subtitle2" component="h3">
                        Total before: {this.state.originalProductCount}
                    </Typography>
                    <Typography variant="subtitle2" component="h3">
                        Total after update: {this.state.updatedLinkedProducts.length}
                    </Typography>
                    <ArrayAttributeRow header="Additions"
                        columnHeaders={[{ name: `Additions (${addedProducts.length})`, key: 'added' }]}
                        keyValuePairs={addedProducts.map(e => { return { 'added': e } })}
                        rowKey="added" />
                    <ArrayAttributeRow header="Removals"
                        columnHeaders={[{ name: `Removals (${removedProducts.length})`, key: 'removed' }]}
                        keyValuePairs={removedProducts.map(e => { return { 'removed': e } })}
                        rowKey="added" />
                </React.Fragment>
            });
    }

    render() {

        return (
            this.state.loading ? <SkeletonForm /> :
                <React.Fragment>

                    <Typography variant="body1" component="h2" style ={{marginLeft: '20px'}}>
                        Select Products to Link with Project: {this.state.projectId}
                    </Typography>

                    <div className="action-button-container">
                        <Button type="submit" variant="contained" color='primary' onClick={this.handleSubmit.bind(this)}>
                            Update
                        </Button>
                    </div>

                    <div style={{ overflow: 'auto', padding: '20px'}}>
                    <TransferList
                        {...this.props}
                        choicesLabel="Unlinked Products"
                        chosenLabel="Linked Products"
                        searchBoxLabel="Search unlinked Products"
                        searchBoxSelectedLabel="Search linked Products"
                        selectedMessage="Selected Products here will allow Analysts in this Project read-only access to the Products via their desktop"
                        leftData={this.state.products.map(p => p.name).filter(p => !this.state.linkedProducts.includes(p))}
                        rightData={this.state.linkedProducts}
                        setChosenDataCallback={(data) => this.setState({ updatedLinkedProducts: data })}
                    />
                    </div>
                </React.Fragment>
        );
    }
}

export default wrap(LinkProductsScreen)
