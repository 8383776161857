//https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
//Provides the useParams hook to a class component when wrapped as a HOC
import { useParams } from 'react-router-dom';

export const withParams = (Component) => {
  const Wrapper = (props) => {
    const urlParams = useParams();

    return (
      <Component
        urlParams={urlParams}
        {...props}
      />
    );
  };

  return Wrapper;
};