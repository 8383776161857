import React, {
  useState, useEffect, useContext, createContext, useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import SpinnerIndeterminateModal from '../components/SpinnerIndeterminateModal'
import Alert from '../components/Alert'

const FeaturesContext = createContext()

const confirmProps = {
  confirmOptions: PropTypes.shape({
    message: PropTypes.string.isRequired,
    callback: PropTypes.func,
    additionalOptions: PropTypes.shape({
      redirect: PropTypes.string,
      okayConfirm: PropTypes.bool,
      additionalComponent: PropTypes.element,
      content: PropTypes.string,
    }),
  }).isRequired,
}

function Confirm({ confirmOptions }) {
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(confirmOptions)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  if (error) { throw error }

  useEffect(() => {
    setConfirm(confirmOptions)
  }, [confirmOptions])

  /**
     * Callback passed to the Alert component, returns whether the user confirmed the action or not
     */
  const onConfirmCallback = async (confirmed) => {
    try {
      setConfirm(null)
      // If user did answer yes then
      if (confirmed) {
        setLoading(true)
        if (confirm.callback) {
        // Run the callback
          await confirm.callback()
        }
        if (confirm.additionalOptions?.redirect) {
          navigate(`${confirm.additionalOptions?.redirect}`)
        }
        setLoading(false)
      }
    } catch (err) {
      setError(err)
    }
  }

  return (
    <>
      {loading
        && <SpinnerIndeterminateModal />}
      {confirm
        && (
          <Alert
            okayConfirm={confirm.additionalOptions?.okayConfirm}
            additionalComponent={confirm.additionalOptions?.additionalComponent}
            open={!!confirm}
            callback={onConfirmCallback}
            title={confirm.message}
            content={confirm.additionalOptions?.content}
          />
        )}
    </>
  )
}
Confirm.propTypes = confirmProps

function FeaturesProvider(props) {
  const [confirm, setConfirm] = useState()
  const [activeLoadingRequests, setActiveLoadingRequests] = useState(0)

  const startLoading = () => {
    setActiveLoadingRequests(activeLoadingRequests + 1)
  }

  const stopLoading = () => {
    setActiveLoadingRequests(activeLoadingRequests - 1)
  }

  const providerValue = useMemo(() => ({ setConfirm, startLoading, stopLoading }), [])
  // eslint-disable-next-line react/prop-types -- FeaturesProvider is not an interface
  const { children } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- props spreading is used inside HOC
    <FeaturesContext.Provider value={providerValue} {...props}>
      {children}
      {confirm && <Confirm confirmOptions={confirm} />}
      {activeLoadingRequests > 0
        && <SpinnerIndeterminateModal />}
    </FeaturesContext.Provider>
  )
}
const useFeatures = () => {
  const context = useContext(FeaturesContext)
  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeaturesContext')
  }
  return context
}

export {
  FeaturesProvider,
  useFeatures,
}
