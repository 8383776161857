import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Cancel from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import { connect } from 'react-redux'
import {
  ListItemButton, Typography, TextField, IconButton,
} from '@mui/material'
import PropTypes from 'prop-types'
import absLogo from '../../resources/images/ABS_Logo_240x240_white-01.svg'
import { getRuntimeConfig } from '../util/Config'
import { Role, hasPodAdminRole } from '../enums/Role'
import { appUserShape, asyncOperationShape } from '../propTypeShapes'

const PodTextField = styled(TextField)({
  '& label': {
    color: '#A0AAB4',
  },
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiOutlinedInput-root': {
    color: 'white',
    textTransform: 'uppercase',
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
})

const StyledListItem = styled(ListItemButton, {
})(({ theme }) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  height: '35px',
  borderRadius: '4px',
  margin: '6px 6px',
  padding: '6px 6px',
}
))

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  color: 'white',
  opacity: '0.8',
  justifyContent: 'center',
  minWidth: '32px',
  height: '16px',
}
))

const StyledListItemText = styled(ListItemText, {
})(({ open }) => ({
  color: 'white',
  opacity: '0.8',
  '& span': {
    fontSize: '13px',
    fontWeight: '450',
  },
  visibility: open ? 'visible' : 'hidden',
}
))

const ABSLogoImg = styled('img', {
})(({ open }) => ({
  width: open ? '150px' : '40px',
}
))

const StyledDrawer = styled(Drawer, {
})(({ theme, open }) => (open
  ? {
    zIndex: 1000,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: '200px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.dark,
    '& .MuiPaper-root': {
      width: '200px',
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.primary.dark,
    },
  }
  : {
    zIndex: 1000,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 56,
    backgroundColor: theme.palette.primary.dark,
    '& .MuiPaper-root': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 56,
      backgroundColor: theme.palette.primary.dark,
    },
  }))

const config = getRuntimeConfig()

const propTypes = {
  user: appUserShape.isRequired,
  open: PropTypes.bool.isRequired,
  operations: PropTypes.arrayOf(asyncOperationShape).isRequired,
  closeCallback: PropTypes.func.isRequired,
  openCallback: PropTypes.func.isRequired,
}

function DatalabSideNav({
  user, open, operations, closeCallback, openCallback,
}) {
  const location = useLocation()
  const navigate = useNavigate()

  const [active, setActive] = React.useState()

  const parseActive = (path) => {
    if (path) {
      const pathSegments = path.split('/')
      if (pathSegments[1]) {
        setActive(pathSegments[1])
      } else {
        setActive('')
      }
    }
  }

  React.useEffect(() => {
    parseActive(location.pathname)
  }, [location])

  return (
    <StyledDrawer
      open={open}
      variant="permanent"
    >
      <List component="nav" sx={{ marginTop: '50px' }}>
        {/* Widgets shown based on user roles */}
        {hasPodAdminRole(user.roles)
          && (
            <StyledListItem
              open={open}
              selected={active === 'projects' || active === ''}
              key="projects"
              onClick={() => navigate('/projects')}
            >
              <Tooltip placement="right" title="Projects">
                <StyledListItemIcon>
                  <i className="fas fa-briefcase" />
                </StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Projects" />
            </StyledListItem>
          )}

        {(hasPodAdminRole(user.roles) || user.roles.includes(Role.ANALYST))
        && (
        <StyledListItem
          open={open}
          selected={active === 'vms' || (user.roles.includes(Role.ANALYST) && active === '')}
          key="vms"
          onClick={() => navigate('/vms')}
        >
          <Tooltip
            placement="right"
            title={user.roles.includes(Role.ANALYST) ? 'My Projects' : 'Virtual Machines'}
          >
            <StyledListItemIcon><i className="fas fa-laptop-code" /></StyledListItemIcon>
          </Tooltip>
          <StyledListItemText
            open={open}
            primary={user.roles.includes(Role.ANALYST) ? 'My Projects' : 'Virtual Machines'}
          />
        </StyledListItem>
        )}

        {hasPodAdminRole(user.roles)
          && (
            <StyledListItem
              open={open}
              selected={active === 'users'}
              key="users"
              onClick={() => navigate('/users')}
            >
              <Tooltip placement="right" title="Users">
                <StyledListItemIcon><i className="fa fa-fw fa-user" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Users" />
            </StyledListItem>
          )}

        {hasPodAdminRole(user.roles)
          && (
            <StyledListItem
              open={open}
              selected={active === 'products'}
              key="products"
              onClick={() => navigate('/products')}
            >
              <Tooltip placement="right" title="Products">
                <StyledListItemIcon><i className="far fa-folder-open" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Products" />
            </StyledListItem>
          )}

        {hasPodAdminRole(user.roles)
          && (
            <StyledListItem
              open={open}
              selected={active === 'sessions'}
              key="sessions"
              onClick={() => navigate('/sessions')}
            >
              <Tooltip placement="right" title="Desktop Sessions">
                <StyledListItemIcon><i className="fas fa-eye" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Desktop Sessions" />
            </StyledListItem>
          )}

        {(hasPodAdminRole(user.roles) || user.roles.includes(Role.LIBRARY_ADMIN))
          && (
            <StyledListItem
              open={open}
              selected={active === 'packageManagement'}
              key="packageManagement"
              onClick={() => navigate('/packageManagement')}
            >
              <Tooltip placement="right" title="Package Management">
                <StyledListItemIcon><i className="fa-solid fa-cube" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Packages" />
            </StyledListItem>
          )}

        {(user.roles.includes(Role.BILLING_ADMIN) || user.roles.includes(Role.SYSADMIN))
          && (
            <StyledListItem
              open={open}
              selected={active === 'licencedSoftware'}
              key="licencedSoftware"
              onClick={() => navigate('/licencedSoftware')}
            >
              <Tooltip placement="right" title="Software Summary">
                <StyledListItemIcon><i className="fa fa-fw fa-id-card" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Software Summary" />
            </StyledListItem>
          )}

        {(user.roles.includes(Role.BILLING_ADMIN) || user.roles.includes(Role.SYSADMIN))
          && (
            <StyledListItem
              open={open}
              selected={active === 'reports'}
              key="reports"
              onClick={() => navigate('/reports')}
            >
              <Tooltip placement="right" title="Reports">
                <StyledListItemIcon><i className="fas fa-chart-bar" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Reports" />
            </StyledListItem>
          )}

        {hasPodAdminRole(user.roles)
          && (
            <StyledListItem
              open={open}
              selected={active === 'organisations'}
              key="organisations"
              onClick={() => navigate('/organisations')}
            >
              <Tooltip placement="right" title="Organisations">
                <StyledListItemIcon><i className="fas fa-sitemap" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Organisations" />
            </StyledListItem>
          )}

        {hasPodAdminRole(user.roles)
          && (
            <StyledListItem
              open={open}
              selected={active === 'tags'}
              key="tags"
              onClick={() => navigate('/tags')}
            >
              <Tooltip placement="right" title="Tags">
                <StyledListItemIcon><i className="fas fa-tags" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Tags" />
            </StyledListItem>
          )}

        {(user.roles.includes(Role.POD_OWNER) || user.roles.includes(Role.SYSADMIN))
          && (
            <StyledListItem
              open={open}
              selected={active === 'bannerMessages'}
              key="bannerMessages"
              onClick={() => navigate('/bannerMessages')}
            >
              <Tooltip placement="right" title="Banner Messages">
                <StyledListItemIcon><i className="fas fa-message" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Banner Messages" />
            </StyledListItem>
          )}

        {(user.roles.includes(Role.POD_OWNER) || user.roles.includes(Role.SYSADMIN))
          && (
            <StyledListItem
              open={open}
              selected={active === 'administrators'}
              key="administrators"
              onClick={() => navigate('/administrators')}
            >
              <Tooltip placement="right" title="Administrators">
                <StyledListItemIcon><i className="fas fa-user-tie" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="Administrators" />
            </StyledListItem>
          )}

        <StyledListItem
          open={open}
          selected={active === 'events'}
          key="events"
          onClick={() => navigate('/events')}
        >
          <Tooltip placement="right" title="Action Log">
            <StyledListItemIcon>
              <i className="fas fa-info-circle" />
              {operations.length > 0
                    && (
                      <CircularProgress
                        size={24}
                        sx={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 6,
                          left: 10,
                          color: 'white',
                          opacity: '0.8',

                        }}
                      />
                    )}

            </StyledListItemIcon>
          </Tooltip>
          <StyledListItemText open={open} primary="Action Log" />
        </StyledListItem>

        {user.roles.includes(Role.ANALYST)
          && (
            <StyledListItem
              open={open}
              selected={active === 'myAccount'}
              key="myAccount"
              onClick={() => navigate(`/myAccount/${user.username}`)}
            >
              <Tooltip placement="right" title="My Account">
                <StyledListItemIcon><i className="fa fa-fw fa-user" /></StyledListItemIcon>
              </Tooltip>
              <StyledListItemText open={open} primary="My Account" />
            </StyledListItem>
          )}

      </List>

      <Box sx={{
        padding: '5px',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 'auto',
        width: '100%',
        display: 'flex',
      }}
      >
        {open
          && (
            <>
              {user.roles.includes(Role.SYSADMIN)
                  && (
                  <PodTextField
                    defaultValue={window.localStorage.getItem('sysadmin.pod_override')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        window.localStorage.setItem('sysadmin.pod_override', e.target.value.toUpperCase())
                        window.location.reload()
                      }
                    }}
                    label="Pod Override"
                    InputProps={{
                      inputProps: { style: { textTransform: 'uppercase' } },
                      endAdornment: (
                        <IconButton
                          style={{
                            padding: '5px',
                            color: 'white',
                          }}
                          onClick={() => {
                            window.localStorage.removeItem('sysadmin.pod_override')
                            window.location.reload()
                          }}
                          size="large"
                        >
                          <Cancel />
                        </IconButton>),
                    }}
                  />
                  )}
              <ABSLogoImg
                open={open}
                id="absLogo"
                src={absLogo}
                alt="ABSLogo"
              />
              <Typography sx={{
                fontSize: '0.8em',
                width: '100%',
                fontWeight: '500',
                paddingLeft: '10px',
                color: 'white',
                opacity: '0.8',
                '& span': {
                  fontSize: '0.95em',
                },
              }}
              >
                {`ABS ${user.pod === 'ABS' ? 'DataLab' : 'SEAD'} version ${config.VERSION}`}
              </Typography>

              <Typography
                sx={{
                  transition: 'none',
                  textWrap: 'nowrap',
                  fontSize: '0.6em',
                  width: '100%',
                  paddingLeft: '10px',
                  color: 'white',
                  opacity: '0.8',
                  '& span': {
                    fontSize: '0.95em',
                  },
                }}
              >
                {`© Australian Bureau of Statistics ${new Date().getFullYear()}`}
              </Typography>
            </>
          )}

      </Box>
      <List>
        <StyledListItem
          open={open}
          key="collapse"
          onClick={open
            ? closeCallback : openCallback}
        >
          <Tooltip placement="right" title={open ? 'Collapse' : 'Expand'}>
            <StyledListItemIcon>
              {open
                ? <i className="fa-solid fa-caret-left" />
                : <i className="fa-solid fa-caret-right" />}
            </StyledListItemIcon>
          </Tooltip>
          <StyledListItemText open={open} primary="Collapse" />
        </StyledListItem>
      </List>
    </StyledDrawer>
  )
}

// Using Redux store to manage global state
// https://daveceddia.com/how-does-redux-work/
function mapStateToProps(state) {
  return {
    operations: state.operations,
  }
}

DatalabSideNav.propTypes = propTypes
export default connect(mapStateToProps)(DatalabSideNav)
