import React from 'react'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import {
  Grid,
  Button,
  Typography,
  Link,
  Paper,
  IconButton,
  Chip,
  MenuItem,
} from '@mui/material'
import format from 'date-fns/format'
import HelpIcon from '@mui/icons-material/Help'
import ArrayAttributeRow from '../../components/ArrayAttributeRow.js'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withProject } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import ComplexAttributeRow from '../../components/ComplexAttributeRow.js'
import SkeletonArrayRow from '../../components/SkeletonArrayRow'
import SkeletonActionButtons from '../../components/SkeletonActionButtons'
import MoreMenu from '../../components/MoreMenu'
import { Role } from '../../enums/Role'
import HelpDialog from '../../components/HelpDialog'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary.jsx'

const ScrollablePaper = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '50vh',
  overflowY: 'auto',
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  minHeight: '50vh',
  backgroundColor: '#fafafa',
})

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

const SectionScrollable = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '50vh',
  overflowY: 'auto',
})

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withProject,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

/**
 * Simple list view of a project and its attributes
 */
class ProjectDetailsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade
    this.state = {
      project: undefined,
      products: undefined,
    }
  }

  handleEditBtnClick(event) {
    this.props.navigate('updateProject')
  }

  handleAssignUser = () => {
    this.props.navigate('assignUser')
  }

  handleRemoveUser = () => {
    this.props.navigate('removeUser')
  }

  linkProducts = () => {
    this.props.navigate('linkProducts')
  }

  handleCloseProject = () => {
    this.props.askForConfirmationListener('Are you sure you want to close this project?\n\nThis action will mark the project as closed and remove all user access to it. The project can be restored for up to 180 days before being archived. If the project has a Databricks workspace, the workspace will be deleted immediately and it cannot be restored.', async () => {
      await this.datalabFacade.closeProject(this.props.project.uuid, this.props.user.pod)
      this.props.showSnackbarInProgress(`Close Project (${this.props.project.uuid}) in progress...`)
    })
  }

  handleRestoreProject = () => {
    this.props.askForConfirmationListener('Are you sure you want to restore this project?', async () => {
      await this.datalabFacade.restoreProject(this.props.project.uuid, this.props.user.pod)
      this.props.showSnackbarInProgress(`Restore Project (${this.props.project.uuid}) in progress...`)
    })
  }

  handleProvisionDatabricks = () => {
    this.props.askForConfirmationListener(`Are you sure you want to enable Databricks for ${this.props.project.uuid}?`, async () => {
      this.setState({ enablingDatabricks: true })
      await this.datalabFacade.provisionProjectToDatabricks(this.props.project.uuid, this.props.user.pod)
      this.props.showSnackbarInProgress(`Databricks provisioning (${this.props.project.uuid}) in progress...`)
    })
  }

  handleDeleteDatabricks = () => {
    this.props.askForConfirmationListener(
      `Are you sure you want to delete the Databricks workspace for ${this.props.project.uuid}? WARNING: all workspace objects (notebooks, libraries, files) that have not been exported out of the workspace will be deleted.`,
      async () => {
        this.setState({ enablingDatabricks: true })
        await this.datalabFacade.deleteDatabricks(this.props.project.uuid, this.props.user.pod)
        this.props.showSnackbarInProgress(
          `Delete Databricks (${this.props.project.uuid}) in progress...`
        )
      }
    )
  }

  handleCloneProject = () => {
    this.props.navigate('cloneProject')
  }

  async loadProducts() {
    const products = await this.datalabFacade.getProjectsProducts(this.props.project.uuid)
    this.setState({ products })
    return products
  }

  render() {
    return (
      <RequiredRoleBoundary
        allowedRoles={[Role.ADMIN]}
        functionProps={[
          'onClick',
        ]}
      >
        <div className="action-button-container">
          {!this.props.project ? <SkeletonActionButtons />
            : this.props.project.status === 'OPEN'
              ? (
                <>
                  <Button variant="outlined" color="primary" onClick={this.handleAssignUser.bind(this)}>
                    Assign Users&nbsp;&nbsp;
                    <i className="fas fa-user-plus" />
                  </Button>
                  <Button variant="outlined" color="primary" onClick={this.handleRemoveUser.bind(this)}>
                    Remove Users&nbsp;&nbsp;
                    <i className="fas fa-user-minus" />
                  </Button>
                  <Button variant="outlined" color="primary" onClick={this.linkProducts.bind(this)}>
                    Link/Unlink Products&nbsp;&nbsp;
                    <i className="fas fa-bezier-curve" />
                  </Button>
                  <Button variant="contained" color="primary" onClick={this.handleEditBtnClick.bind(this)}>
                    Edit&nbsp;&nbsp;
                    <i className="fas fa-edit" />
                  </Button>
                  <MoreMenu
                    sx={{ float: 'right' }}
                    open
                  >
                    <MenuItem key="close" onClick={this.handleCloseProject.bind(this)} disableRipple>
                      <span>
                        Close Project&nbsp;&nbsp;
                        <i className="fas fa-ban" />
                      </span>
                    </MenuItem>
                    <MenuItem key="clone" onClick={this.handleCloneProject.bind(this)} disableRipple>
                      <span>
                        Copy details to a new Project &nbsp;&nbsp;
                        <i className="fas fa-plus" />
                      </span>
                    </MenuItem>
                  </MoreMenu>
                </>
              )
              : this.props.project.status === 'CLOSED'
                ? (
                  <>
                    <Button variant="contained" color="primary" onClick={this.handleRestoreProject.bind(this)}>
                      Restore&nbsp;&nbsp;
                      <i className="fas fa-trash-restore" />
                    </Button>
                    <MoreMenu
                      sx={{ float: 'right' }}
                      open
                    >
                      <MenuItem key="clone" onClick={this.handleCloneProject.bind(this)} disableRipple>
                        <span>
                          Copy details to a new Project &nbsp;&nbsp;
                          <i className="fas fa-plus" />
                        </span>
                      </MenuItem>
                    </MoreMenu>
                  </>
                )
                : this.props.project.status === 'ARCHIVED'
                            && <></>}
        </div>

        <Grid container direction="row" alignItems="stretch" className="details-grid">
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Basic Attributes
                    </Typography>
                  </SectionHeader>

                  <ComplexAttributeRow keyValuePairs={[
                    { header: 'Project Name', value: this.props.project.projectName },
                    { header: 'Project ID', value: this.props.project.uuid },
                    { header: 'Project Storage Account', value: this.props.project.projectStore },
                    { header: 'Product Storage Account', value: this.props.project.productStorageAccountName },
                    { header: 'Project Storage Size(TB)', value: this.props.project.projectStorageSize },
                    { header: 'Description', value: this.props.project.description },
                    { header: 'Organisation', value: this.props.project.organisation },
                    { header: 'Status', value: this.props.project.status, status: true },
                    { header: 'Start Date', value: this.props.project.startDate, date: true },
                    { header: 'End Date', value: this.props.project.endDate, date: true },
                    { header: 'Default VM Size', value: this.props.project.defaultVMsize },
                  ]}
                  />

                </UnscrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Lead Researcher
                    </Typography>
                  </SectionHeader>

                  <ComplexAttributeRow keyValuePairs={[
                    { header: 'Contact Name', value: this.props.project.projectContact },
                    { header: 'Contact Email', value: this.props.project.contactEmail },
                    { header: 'Contact Phone', value: this.props.project.contactPhone },

                  ]}
                  />

                </UnscrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project ? <SkeletonArrayRow />
              : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Users (
                      {this.props.project.users.length}
                      )
                    </Typography>
                  </SectionHeader>

                  <ArrayAttributeRow
                    header="Users"
                    columnHeaders={[{ name: 'Username', key: 'userName', linkTo: 'users' }]}
                    keyValuePairs={this.props.project.users.map((u) => ({ userName: u }))}
                    rowKey="userName"
                  />

                </ScrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project ? <SkeletonArrayRow />
              : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Cost Analysis
                    </Typography>
                    <div className="sub-action-button-container">
                      <IconButton
                        onClick={() => this.setState({ showingCostAnalysisHelp: true })}
                        aria-label="cost analysis help"
                        size="large"
                      >
                        <HelpIcon />
                      </IconButton>
                    </div>
                  </SectionHeader>

                  {this.state.showingCostAnalysisHelp
                                    && (
                                    <HelpDialog
                                      open={this.state.showingCostAnalysisHelp}
                                      callback={() => this.setState({ showingCostAnalysisHelp: false })}
                                      title="Cost Analysis"
                                      message="Shows aggregated costs per month for the Project. These are an estimate only and do not reflect the actual charge incurred due to currency fluctuation and usage latency. Refer to the end of month reports for finalised usage charges. Standard Usage refers to all Azure resources the Project directly consumes including (but not limited to): Virtual Machines, Storage, Networking. Databricks Usage refers to all Azure resources the allocated Databricks instance consumes including but not limited to: Virtual Machines deployed as Databricks cluster workers, Storage and Networking"
                                    />
                                    )}

                  {this.props.project.costReports
                    ? (
                      <ArrayAttributeRow
                        header="Cost Analysis"
                        footNote={this.props.project.costReportLastUpdate ? `Last updated ${format(new Date(this.props.project.costReportLastUpdate), 'dd MMM yyyy h:mma z')}` : ''}
                        columnHeaders={[{ name: 'Month', key: 'billingPeriodEndDate' },
                          { name: 'Standard Usage', key: 'totalPretaxCostResourceGroup', currency: true },
                          { name: 'Databricks Usage', key: 'totalPretaxCostDatabricks', currency: true },
                          { name: 'Total Usage', key: 'totalPretaxCost', currency: true }]}
                        keyValuePairs={
                                            [
                                              {
                                                billingPeriodEndDate: 'Total',
                                                totalPretaxCostResourceGroup: this.props.project.totalPretaxCostResourceGroup ? this.props.project.totalPretaxCostResourceGroup : 0,
                                                totalPretaxCostDatabricks: this.props.project.totalPretaxCostDatabricks ? this.props.project.totalPretaxCostDatabricks : 0,
                                                totalPretaxCost: this.props.project.totalPretaxCost ? this.props.project.totalPretaxCost : 0,
                                              },
                                            ].concat(

                                              Object.keys(this.props.project.costReports).sort().reverse().map((costReportKey) => {
                                                const reportDate = new Date(Date.parse(this.props.project.costReports[costReportKey].billingPeriodEndDate))
                                                const formattedBillingPeriod = reportDate.toLocaleString('default', { month: 'long', year: 'numeric' })
                                                return {
                                                  billingPeriodEndDate: formattedBillingPeriod,
                                                  totalPretaxCostResourceGroup: this.props.project.costReports[costReportKey].totalPretaxCostResourceGroup ? this.props.project.costReports[costReportKey].totalPretaxCostResourceGroup : this.props.project.costReports[costReportKey].totalPretaxCost,
                                                  totalPretaxCostDatabricks: this.props.project.costReports[costReportKey].totalPretaxCostDatabricks ? this.props.project.costReports[costReportKey].totalPretaxCostDatabricks : 0,
                                                  totalPretaxCost: this.props.project.costReports[costReportKey].totalPretaxCost,
                                                }
                                              })
                                            )
}
                        rowKey="billingPeriodEndDate"
                      />
                    )
                    : (
                      <ArrayAttributeRow
                        header="Cost Analysis"
                        columnHeaders={[{ name: 'Month', key: 'billingPeriodEndDate' }, { name: 'Standard Usage', key: 'totalPretaxCost', currency: true }]}
                        keyValuePairs={
                                            [{ billingPeriodEndDate: 'No billing data available' }]
}
                        rowKey="billingPeriodEndDate"
                      />
                    )}
                </ScrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project ? <SkeletonArrayRow />
              : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Products
                      {' '}
                      {this.state.products ? `(${this.state.products.length})` : ''}
                    </Typography>
                  </SectionHeader>

                  <ArrayAttributeRow
                    header="Products"
                    simpleArray
                    refreshTimestamp={this.props.timestamp}
                    lazy={this.loadProducts.bind(this)}
                    columnHeaders={[{ name: 'Product Identifier', key: 'name' }]}
                    rowKey="name"
                  />
                </ScrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project ? <SkeletonArrayRow />
              : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Databricks
                    </Typography>
                    {this.props.project && !this.props.project.databricksWorkspace
                                        && (
                                        <div className="sub-action-button-container">
                                          <Button variant="outlined" color="primary" onClick={this.handleProvisionDatabricks.bind(this)} disabled={this.props.project.status !== 'OPEN' || this.state.enablingDatabricks}>
                                            Enable Databricks&nbsp;&nbsp;
                                            <i className="fas fa-check" />
                                          </Button>
                                        </div>
                                        )}
                    {this.props.project.databricksWorkspace && (
                      <div className="sub-action-button-container">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.handleDeleteDatabricks.bind(this)}
                          disabled={this.props.project.status !== 'OPEN' || this.state.enablingDatabricks}
                        >
                          Delete Databricks&nbsp;&nbsp;
                          <i className="fa fa-trash" />
                        </Button>
                      </div>
                      )
                    }
                  </SectionHeader>

                  {this.props.project.databricksWorkspace
                    ? (
                      <>
                        <div style={{ margin: '10px' }}>
                          <Typography variant="caption" component="p">
                            This Project has been allocated a Databricks workspace
                          </Typography>
                        </div>
                        <ComplexAttributeRow
                          keyValuePairs={[
                            {
                              header: 'Workspace',
                              value: this.props.project.databricksWorkspace,
                            },
                          ]}
                        />
                      </>
                    )
                    : (
                      <div style={{ margin: '10px' }}>
                        <Typography variant="caption" component="p">
                          This Project does not have a Databricks workspace
                        </Typography>
                      </div>
                    )}
                </ScrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project
              ? <SkeletonArrayRow />
              : (
                <ScrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Project Storage Size Change History
                    </Typography>
                  </SectionHeader>
                  <ArrayAttributeRow
                    header="history"
                    columnHeaders={[
                      { name: 'Size (TB)', key: 'newProjectStorageSize' },
                      { name: 'Date', key: 'date', dateTime: true },
                    ]}
                    keyValuePairs={this.props.project.projectStorageSizeChangelog ? this.props.project.projectStorageSizeChangelog.map((s) => ({ newProjectStorageSize: s.newProjectStorageSize, date: s.date })).reverse() : []}
                    rowKey="date"
                  />
                </ScrollablePaper>
              )}
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            {!this.props.project
              ? <SkeletonArrayRow />
              : (
                <SectionScrollable variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Project Tags
                    </Typography>
                  </SectionHeader>

                  {this.props.project.tags
                    ? this.props.project.tags.map((tag) => (
                      <Chip
                        key={`${tag.tagKey}-${tag.tagValue}`}
                        tabIndex={-1}
                        label={`${tag.tagKey}: ${tag.tagValue}`}
                      />
                    ))
                    : []}
                </SectionScrollable>
              )}
          </Grid>
        </Grid>
      </RequiredRoleBoundary>
    )
  }
}

export default wrap((ProjectDetailsScreen))
