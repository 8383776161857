import React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import CopyToClipboard from './CopyToClipboard'

export default function AlertDialog(props) {
  const [open] = React.useState(props.open)
  const [showingFullPassword, setShowingFullPassword] = React.useState(false)

  function handleYes() {
    props.callback(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Temporary password</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please copy the password, it will not be accessible after you close this window
          </DialogContentText>
          <Typography variant="h5" component="h3">

            {
                showingFullPassword
                  ? props.tempPassword
                  : props.tempPassword.replace(/.*/g, '********')
              }

            <div>
              <Button
                variant="outlined"
                onClick={() => { setShowingFullPassword(!showingFullPassword) }}
              >
                {showingFullPassword ? 'Hide' : 'Show'}
              </Button>

              <CopyToClipboard>
                {({ copy }) => (
                  <Button
                    className="copy-to-clipboard-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => copy(props.tempPassword)}
                  >
                    Copy
                  </Button>
                )}
              </CopyToClipboard>
            </div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
