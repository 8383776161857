import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { UserFixableError } from '../util/Exceptions'
import { getRuntimeConfig } from '../util/Config'
import blocked from '../../resources/images/blocked.svg'

const Heading = styled('Typography')(() => ({
  display: 'flex',
  fontSize: '30px',
  fontWeight: '450',
  opacity: 0.8,

}))

const Subtitle = styled('Typography')(() => ({
  // margin: 'auto'

}))

const Box = styled('Container')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'auto',
  height: 'inherit',
  justifyContent: 'center',
  gap: '10px',

}))

const propTypes = {
  children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
}
const config = getRuntimeConfig()

const DEFAULT_ERROR = 'An error occurred'

/**
 * Acts as an error boundary when wrapping another component
 * All errors in the child component are caught and displayed in place of the child
 * Note that errors in async functions and errors in event handlers wont get caught automatically
 * To workaround this catch the error and then this.setState(() => {throw error})
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errorOccurred: false }
  }

  componentDidCatch(error) {
    this.setState({ errorOccurred: true, error })
  }

  render() {
    const { errorOccurred, error } = this.state
    const { children } = this.props

    return errorOccurred
      ? (
        <Box>
          <img id="blocked" alt="blocked-icon" src={blocked} />
          <Heading>
            {error instanceof UserFixableError ? 'Sorry, that\'s not allowed right now.' : DEFAULT_ERROR}
          </Heading>
          {error instanceof UserFixableError || config.PROPERTIES_STAGE !== 'prod'
            ? (
              <Subtitle>
                {error.message}
              </Subtitle>
            ) : ''}
          <Button variant="contained" onClick={() => { window.location.reload() }}>
            Go Back
          </Button>
        </Box>
      )
    // Otherwise render wrapped components
      : children
  }
}
ErrorBoundary.propTypes = propTypes
