import React from 'react'
import { styled } from '@mui/material/styles'
import flow from 'lodash.flow'
import {
  Grid,
  Button,
  Typography,
  Paper,
  Chip,
} from '@mui/material'
import ArrayAttributeRow from '../../components/ArrayAttributeRow'
import asBaseScreen from '../../screenWrappers/BaseScreen'
import { withUser } from '../../screenWrappers/DataProviders'
import { withSnackbarsFeature, withConfirmFeature } from '../../screenWrappers/Features'
import ComplexAttributeRow from '../../components/ComplexAttributeRow.js'
import TempPasswordDialog from '../../components/TempPasswordDialog.js'
import { generateInitialAzureAdPassword } from '../../util/PasswordUtils'
import SkeletonArrayRow from '../../components/SkeletonArrayRow'
import SkeletonActionButtons from '../../components/SkeletonActionButtons'
import { withRouter } from '../../screenWrappers/withRouter'
import RequiredRoleBoundary from '../../components/RequiredRoleBoundary'
import { Role } from '../../enums/Role'

const SectionHeader = styled('div')({
  padding: '10px',
  '& h3': {
    display: 'inline',
    margin: '5px',
  },
})

const UnscrollablePaper = styled(Paper)({
  margin: '20px',
  minHeight: '50vh',
  backgroundColor: '#fafafa',
})

const SectionScrollable = styled(Paper)({
  margin: '20px',
  backgroundColor: '#fafafa',
  height: '50vh',
  overflowY: 'auto',
})

// Screen requires the following data providers and features injected
const wrap = flow([
  withRouter,
  withUser,
  withConfirmFeature,
  withSnackbarsFeature,
  asBaseScreen,
])

/**
* Displays user details in simple list view
*/
class UserDetailsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.datalabFacade = props.datalabFacade

    this.state = {
      user: undefined,
      vms: undefined,
      showingTempPassword: false,
      tempPassword: undefined,
    }
  }

  componentDidUpdate(prevProps) {
    // rather than compare all the data, the data provider attaches a timestamp - if it changes then we know to update
    if (prevProps.timestamp !== this.props.timestamp) {
      const vms = this.props.userData.VMs.map((vm) => {
        vm.active = !!((this.props.userData.activeVm && this.props.userData.activeVm === vm.VM))
        return vm
      })
      this.setState({ user: this.props.userData, vms })
    }
  }

  handleEvictUser = () => {
    this.props.askForConfirmationListener('Are you sure you want to evict this user?\n\nThis action will permanently delete this user. This action can not be undone.', async () => {
      await this.datalabFacade.evictUser(this.state.user.userName, this.props.user.pod)
      this.props.showSnackbarInProgress(`Evict User (${this.state.user.userName}) in progress...`)
    })
  }

  handleDisableUser = () => {
    this.props.askForConfirmationListener('Are you sure you want to disable this user?\n\nThis action will disable the user\'s ability to sign in. This user can be enabled again at a later date.', async () => {
      await this.datalabFacade.disableUser(this.state.user.userName, this.props.user.pod)
      this.props.showSnackbarInProgress(`Disable User (${this.state.user.userName}) in progress...`)
    })
  }

  handleEnableUser = () => {
    this.props.askForConfirmationListener('Are you sure you want to enable this user?', async () => {
      await this.datalabFacade.enableUser(this.state.user.userName, this.props.user.pod)
      this.props.showSnackbarInProgress(`Enable User (${this.state.user.userName}) in progress...`)
    })
  }

  handleUnassignAllProjectsClick = (event) => {
    const listOfProjects = this.state.vms.map((vm) => vm.project)
    this.props.askForConfirmationListener('Are you sure you want to remove this User from all of their assigned Projects?', async () => {
      await this.datalabFacade.unassignAllProjects(this.state.user.userName, listOfProjects, this.props.user.pod)
      this.props.showSnackbarInProgress(`Unassign User from Projects (${listOfProjects.length} Projects -> ${this.state.user.userName}) in progress...)`)
    })
  }

  handleEditBtnClick(event) {
    this.props.navigate('updateUser')
  }

  handleRestoreBtnClick = () => {
    const user = {
      userName: this.state.user.userName,
      initialPassword: generateInitialAzureAdPassword(),
      podId: this.props.user.pod,
    }

    this.props.askForConfirmationListener(
      'Are you sure you want to restore this User?',
      async () => {
        await this.props.datalabFacade.restoreUser(user)
        this.props.showSnackbarInProgress(`Restore User (${user.userName}) in progress...`)
        this.setState({ showingTempPassword: true, tempPassword: user.initialPassword })
      }
    )
  }

  handleChangeActiveDsvmBtnClick(event) {
    this.props.navigate('changeActiveDsvm')
  }

  handleResetPasswordBtnClick(event) {
    this.props.askForConfirmationListener(
      `The user '${this.state.user.userName}' will be assigned a temporary password which must be changed on next sign in. Are you sure you want to reset this users password?`,
      async () => {
        const tempPassword = generateInitialAzureAdPassword()
        await this.props.datalabFacade.resetUserPassword(this.state.user.userName, tempPassword)
        this.props.showSnackbarSuccess(`Reset password for (${this.state.user.userName}) succeeded`)
        this.setState({ showingTempPassword: true, tempPassword })
      }
    )
  }

  handleResetMFABtnClick(event) {
    this.props.askForConfirmationListener(
      `The user '${this.state.user.userName}' will be required to reregister an MFA method and password recovery email/phone. Are you sure you want to reset this user's authentication methods?`,
      async () => {
        await this.props.datalabFacade.resetUserMFA(this.state.user.userName)
        this.props.showSnackbarSuccess(`Reset authentication methods for (${this.state.user.userName}) succeeded`)
      }
    )
  }

  render() {
    return (
      <>
        {this.state.showingTempPassword
                    && (
                    <TempPasswordDialog
                      open={this.state.showingTempPassword}
                      tempPassword={this.state.tempPassword}
                      callback={() => { this.setState({ showingTempPassword: false, tempPassword: undefined }) }}
                    />
                    )}
        {!this.state.user
          ? (
            <div className="action-button-container">
              <SkeletonActionButtons />
            </div>
          )
          : (
            <div className="action-button-container">
              <RequiredRoleBoundary
                allowedRoles={[Role.USER_ADMIN]}
                functionProps={[
                  'onClick',
                ]}
              >
                {(this.state.user.status === 'ACTIVE' || this.state.user.status === 'DISABLED')
                            && (
                            <Button variant="outlined" color="primary" onClick={this.handleEvictUser.bind(this)}>
                              Evict&nbsp;&nbsp;
                              <i className="fas fa-user-minus" />
                            </Button>
                            )}
                {this.state.user.status === 'ACTIVE'
                            && (
                            <Button variant="outlined" color="primary" onClick={this.handleDisableUser.bind(this)}>
                              Disable&nbsp;&nbsp;
                              <i className="fas fa-user-slash" />
                            </Button>
                            )}
                {this.state.user.status === 'DISABLED'
                            && (
                            <Button variant="outlined" color="primary" onClick={this.handleEnableUser.bind(this)}>
                              Enable&nbsp;&nbsp;
                              <i className="fas fa-user-check" />
                            </Button>
                            )}
                {this.state.user.status !== 'INACTIVE'
                            && (
                            <Button variant="outlined" color="primary" onClick={this.handleResetPasswordBtnClick.bind(this)}>
                              Reset Password&nbsp;&nbsp;
                              <i className="fas fa-key" />
                            </Button>
                            )}
                {this.state.user.status !== 'INACTIVE'
                            && (
                            <Button variant="outlined" color="primary" onClick={this.handleResetMFABtnClick.bind(this)}>
                              Reset Auth Methods&nbsp;&nbsp;
                              <i className="fas fa-user-lock" />
                            </Button>
                            )}
              </RequiredRoleBoundary>
              <RequiredRoleBoundary
                allowedRoles={[Role.ADMIN]}
                functionProps={[
                  'onClick',
                ]}
              >
                {this.state.user.status === 'ACTIVE'
                            && (
                            <Button disabled={this.state.vms.length === 0} variant="outlined" color="primary" onClick={this.handleChangeActiveDsvmBtnClick.bind(this)}>
                              Change Active VM&nbsp;&nbsp;
                              <i className="fas fa-laptop-medical" />
                            </Button>
                            )}
                {this.state.user.status === 'ACTIVE'
                            && (
                            <Button disabled={this.state.vms.length === 0} variant="outlined" color="primary" onClick={this.handleUnassignAllProjectsClick.bind(this)}>
                              Remove from all projects&nbsp;&nbsp;
                              <i className="fas fa-user-times" />
                            </Button>
                            )}
              </RequiredRoleBoundary>
              <RequiredRoleBoundary
                allowedRoles={[Role.USER_ADMIN]}
                functionProps={[
                  'onClick',
                ]}
              >
                {this.state.user.status === 'ACTIVE'
                            && (
                            <Button variant="contained" color="primary" onClick={this.handleEditBtnClick.bind(this)}>
                              Edit&nbsp;&nbsp;
                              <i className="fas fa-edit" />
                            </Button>
                            )}
                {this.state.user.status === 'INACTIVE'
                            && (
                            <Button variant="contained" color="primary" onClick={this.handleRestoreBtnClick.bind(this)}>
                              Restore&nbsp;&nbsp;
                              <i className="fas fa-trash-restore" />
                            </Button>
                            )}
              </RequiredRoleBoundary>
            </div>
          )}

        <Grid container direction="row" alignItems="stretch" className="details-grid">
          <Grid item xs={6}>
            {!this.state.user ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Basic Attributes
                    </Typography>
                  </SectionHeader>
                  {!this.state.user ? <SkeletonArrayRow />
                    : (
                      <ComplexAttributeRow keyValuePairs={[
                        { header: 'Username', value: this.state.user.userName },
                        { header: 'Name', value: this.state.user.displayName },
                        { header: 'Organisation', value: this.state.user.organisation },
                        { header: 'Email', value: this.state.user.contactEmail },
                        { header: 'Phone', value: this.state.user.phone },
                        { header: 'Date Registered', value: this.state.user.registeredDate, date: true },
                        { header: 'Status', value: this.state.user.status, status: true },
                      ]}
                      />
                    )}
                </UnscrollablePaper>
              )}
          </Grid>
          <Grid item xs={6}>
            {!this.state.user ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Virtual Machines (
                      {this.state.vms.length}
                      )
                    </Typography>
                  </SectionHeader>

                  <ArrayAttributeRow
                    header="Virtual Machines"
                    className="sub-action-container"
                    columnHeaders={
                                        [
                                          { name: 'Project', key: 'project', linkTo: 'projects' },
                                          { name: 'VM Name', key: 'VM', linkTo: 'vms' },
                                          { name: 'Active', key: 'active', boolean: true },
                                        ]
}
                    keyValuePairs={this.state.vms}
                    rowKey="project"
                  />
                </UnscrollablePaper>
              )}
          </Grid>
          <Grid item xs={6}>
            {!this.state.user ? <SkeletonArrayRow />
              : (
                <UnscrollablePaper variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      Account Settings
                    </Typography>
                  </SectionHeader>

                  <ComplexAttributeRow keyValuePairs={[
                    { header: 'Email Opt Out', value: this.state.user.emailOptOut, boolean: true },
                  ]}
                  />

                </UnscrollablePaper>
              )}
          </Grid>

          <Grid item xs={6} lg={6} xl={4}>
            {!this.props.user
              ? <SkeletonArrayRow />
              : (
                <SectionScrollable variant="outlined">
                  <SectionHeader>
                    <Typography variant="h6" component="h3">
                      User Tags
                    </Typography>
                  </SectionHeader>

                  {this.state.user && this.state.user.tags
                    ? this.state.user.tags.map((tag) => (
                      <Chip
                        key={`${tag.tagKey}-${tag.tagValue}`}
                        tabIndex={-1}
                        label={`${tag.tagKey}: ${tag.tagValue}`}
                      />
                    ))
                    : []}
                </SectionScrollable>
              )}
          </Grid>
        </Grid>
      </>
    )
  }
}

export default wrap((UserDetailsScreen))
